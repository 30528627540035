.affiliate-form {
    padding: 24px 8.5% 24px 8.5%;
}

.affiliate-form h1 {
    font-size: 3.4rem;
}

.affiliate-form p {
    text-align: center;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.affiliate-form button {
    width: 80%;
}

.affiliate-form-question {
    padding: 12px 0 12px 0;
}

.affiliate-submit-button-label {
    padding: 12px 0 12px 0;
}

.affiliate-form-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 0 24px 0;
}

.affiliate-form-items div {
    flex-basis: 32%;
}

@media screen and (max-width: 1500px) {
    .affiliate-form {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .affiliate-form {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .affiliate-form {
        padding: 24px 2.5% 24px 2.5%;
    }
}