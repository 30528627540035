.recover {
    background: #1e272e; 
    overflow: auto;
}

.recovery-form {
    height: 25%;
    margin: 120px 25% 48px 25%;
    border-style: solid;
    color: #fff;
}

.recovery-form h1 {
    font-size: 3.6rem;
}

.recovery-form form {
    margin: 0 25% 0 25%;
}

.recovery-buttons {
    display: flex;
    flex-direction: column;
    margin: 24px 0 24px 0;
}

.recovery-buttons form {
    width: 100%;
    margin: 0;
}

.recovery-buttons button {
    width: 100%;
    margin: 12px 0 12px 0;
}

.recovery-success-button form {
    width: 100%;
    margin: 24px 0 24px 0;
}

.denied-button:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

@media screen and (max-width: 1200px) {
    .recovery-form {
        margin: 0;
        border-style: none none solid none;
    }

    .recovery-form .main-form {
        margin: 0 5% 0 5%;
    }
}