.donate {
    background: #1e272e; 
    overflow: auto;
    border: #fff;
    color: #fff;
}

.donate-header {
    margin: 148px 5% 48px 5%;
    background: #2d3436;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, #fff 0px 0px 0px 3px;
    border-radius: 50px;
    color: #fff;
}

.donate-header h1 {
    padding: 1%;
    font-size: 3rem;
    margin: 0;
}

.donation-flexbox { 
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    padding-left: 0;
    border-style: none none solid none;
}

.donation-flexbox div {
    flex-basis: 40%;
}

.donation-image {
    max-width: 100%;
    max-height: 100%;
}

.donation-button {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background: none;
    border: solid;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    color: black;
    background: #fdcb6e;
    font-family: 'Poppins', sans-serif;
    transition: all 300ms ease-in-out;
}


@media screen and (max-width: 980px) {
    .donation-flexbox {
        flex-direction: column;
        padding: 24px 1.5% 0 1.5%;
    }
    
    .donation-image {
        max-width: 500px;
        margin-top: 48px;
    }
}

@media screen and (max-width: 510px) {
    .donation-image {
        max-width: 450px;
    }
}

@media screen and (max-width: 460px) {
    .donation-image {
        max-width: 400px;
    }
}

@media screen and (max-width: 410px) {
    .donation-image {
        max-width: 350px;
    }
}

@media screen and (max-width: 360px) {
    .donation-image {
        max-width: 300px;
    }
}

@media screen and (max-width: 310px) {
    .donation-image {
        max-width: 200px;
    }
}