.event-signup {
    border-style: solid;
    padding: 1%;
    margin: 24px 25% 24px 25%
}

.instruction-text {
    font-size: 2rem;
}

@media screen and (max-width: 1200px) {
    .event-signup {
        border-style: none none solid none;
        margin: 24px 0 24px 0;
    }

    .event-signup button {
        margin-bottom: 24px;
    }

}

@media screen and (max-width: 810px) {
    .instruction-text {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 710px) {
    .instruction-text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 610px) {
    .instruction-text {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 480px) {
    .instruction-text {
        font-size: 1.2rem;
    }

    .event-signup .p-inputtext {
        width: 90%;
    }
}