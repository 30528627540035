.find {
    background: #1e272e;
    overflow: auto;
}

/* Find Form */

.find-form {
    padding: 48px 2% 48px 2%;
    border-style: none none solid none;
    color: #fff;
}

.find-form h2 {
    font-size: 3.6rem;
    margin: 0;
}

.find-form button {
    width: 60%;
    margin-top: 24px;
}

.find-form p {
    font-size: 1.6rem;
}

/* Redirect Prompt */

.redirect-prompt {
    padding: 48px 5% 48px 5%;
    color: #fff;
}

.prompt-button {
    flex-basis: 45%;
}

/* Text Area */


.find-area-inputs {
    margin: 1% 15% 1% 15%;
}


/* Error */

.error-message {
    color: rgb(238, 80, 80);
    font-size: 1.6rem;
}

/* Review Container */
.find-review-container {
    margin: 100px 0 0 0;
}

.find-review-container h2 {
    font-size: 2.6rem;
}


.find-review-container h3 {
    font-size: 1.4rem;
}

/* Status */

.find-review-container .application {
    font-size: 3rem;
    margin-top: 0;
}

.find-review-container .denied-button {
    font-size: 3rem;
    background: rgb(238, 80, 80);
}

.find-review-container .pending-button {
    font-size: 3rem;
    background: #fdcb6e;
    color: #222;
}

.find-review-container .approved-button {
    font-size: 3rem;
    background: #55efc4;
}

.application-status-display {
    margin: 24px 0 24px 0;
}

@media screen and (max-width: 736px) {
    .prompt-button {
        width: 100%;
    }

    .find-form p {
        font-size: 1.4rem;
    }

    .find-form button {
        width: 80%;
    }
}

@media screen and (max-width: 480px) {
    .find-review-container {
        margin: 80px 0 0 0;
    }

    .find-form p {
        font-size: 1.2rem;
    }

    .find-form button {
        width: 100%;
    }

}

