.about {
    background: #1e272e;
    overflow: auto;
    border: #fff;
    color: #fff;
}

.about .header {
    margin: 12px 0 12px 0;
}

/* Nav */

.about-nav {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    list-style: none;
    align-items: center;
    text-align: center;
    border-style: none none solid none;
}

.about-nav button {
    flex-basis: 49%;
}

.selected-about-button {
    background: #273c75;
    border-color: white;
}

/* About Us */

.about-text {
    padding: 24px 8.5% 24px 8.5%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.about-text h1 {
    font-size: 3.2rem;
}

.about-text .hugs-logo {
    max-width: 350px;
}

.about-text p {
    text-align: left;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.float-right {
    float: right;
    margin-left: 10px;
}

.float-left {
    float: left;
    margin-left: 10px;
}

.about-text .about-section-event-photo {
    max-width: 500px;
}

/* Get Involved */

.about-links {
    display: flex;
    justify-content: space-between;
    padding: 1% 5% 1% 5%;
    list-style: none;
    align-items: center;
    text-align: center;
}

.about-links div {
    flex-basis: 24%;
}

.about-links button {
    width: 100%;
}

/* Sponsorships */

.sponsorship-text {
    padding: 24px 8.5% 24px 8.5%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.tier-section h1 {
    font-size: 3.2rem;
    padding: 12px 0 12px 0;
}

.sponsorship-type {
    font-size: 3.2rem;
    padding: 12px 0 12px 0;
}

.sponsorship-subtitle {
    font-size: 2.2rem;
    padding: 0 0 12px 0;
}

.sponsorship-text img {
    max-width: 350px;
}

.sponsorship-text p {
    text-align: center;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.tier-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.sponsorship-container {
    outline: #fff;
    outline-style: solid;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
}

.sponsorship-container h1 {
    margin: 12px;
}

.sponsorship-container h2 {
    margin: 6px;
}

.sponsorship-container ul {
    list-style-type: circle;
    text-align: left;
}

.sponsorship-container li {
    margin-bottom: 10px;
}

/* Sponsorship tier colors */

.tier-amount {
    color: #2ecc71;
}

.bronze {
    color: #c59d5f;
}

.silver {
    color: #d3d3d3;
}

.gold {
    color: #ffd700;
}

.platinum {
    color: #e5e4e2;
}


/* Meet The Team */

/* .meet-the-team-content {
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
} */

/* Team Large */

.team-container-large {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 8.5% 0 8.5%;
    margin: 0;
}

.title-section {
    display: flex;
    flex-direction: column;
}

.description-section {
    align-self: center;
}

.team-box-large {
    display: flex;
    margin: 24px 0 24px 0;
    padding: 24px;
    border-style: solid;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-box-large-reverse {
    display: flex;
    padding: 24px;
    margin: 24px 0 24px 0;
    border-style: solid;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.row-reverse {
    flex-direction: row-reverse;
}

.team-box-large h1 {
    font-size: 3.2rem;
    margin: 12px 0 12px 0;
}

.team-box-large p {
    font-size: 1.2rem;
    padding: 12px 5% 12px 5%;
}

.team-image-large {
    max-width: 400px;
    border-radius: 80px;
    border: #fff;
    border-width: 3px;
    margin: 12px 0 12px 0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-title-large {
    font-family: 'Poppins', sans-serif;
    font-size: 3.2rem;
    margin: 12px 0 12px 0;
}

.team-subtitle-large {
    font-size: 2.2rem;
    font-family: 'Poppins', sans-serif;
    margin: 12px 0 12px 0;
}

/* Team Medium */

.team-container-medium {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    margin: 0;
}

.team-box-medium {
    border-style: solid;
    color: #fff;
    padding: 0 24px 0 24px;
    flex-basis: 45%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-image-medium {
    border-radius: 60px;
    border: #fff;
    border-width: 3px;
    height: auto;
    margin: 12px 0 12px 0;
    width: 400px;
    height: 400px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-title-medium {
    font-family: 'Poppins', sans-serif;
    font-size: 2.2rem;
    margin: 12px 0 12px 0;
}

.team-subtitle-medium {
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    margin: 12px 0 12px 0;
}

/* Team Small / Board of Directors */

.small-header h1 {
    font-size: 3.2rem;
}

.team-container-small {
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    margin: 0;
}

.team-box-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    justify-content: space-between;
    flex-basis: 20%;
    padding: 0 12px 12px 12px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-image-small {
    border-radius: 15px;
    border: #fff;
    height: auto;
    max-width: 300px;
    margin: 12px 0 12px 0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-title-small {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    margin: 12px 0 12px 0;
}

.team-subtitle-small {
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    margin: 12px 0 12px 0;
}

/* Ambassadors Section */


/* Change the flex direction when adding more items later */
.ambassadors-container {
    padding: 24px 8.5% 24px 8.5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-style: none none solid none;
}

.ambassador-box {
    width: 24%;
    border-style: solid;
    align-self: center;
}

.ambassador-image {
    border-radius: 15px;
    width: 50%;
    margin: 12px 0 12px 0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/* Join Section */

.team-join-container {
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 24px 8.5% 48px 8.5%;
    border-style: none none solid none;
}

/* Small Screens */

@media screen and (max-width: 1600px) {
    .team-image-small {
        max-width: 250px;
    }
}

@media screen and (max-widtH: 1500px) {
    .about-text {
        padding: 24px 6.5% 24px 6.5%;
    }
    
    .about-nav {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1374px) {
    .team-container-large {
        display: grid;
        grid-template-columns: repeat(1, auto);
        justify-content: space-evenly;
        padding: 0 5% 48px 5%;
        border-style: none;
    }

    .team-box-large {
        margin-top: 24px;
        padding: 2.5%;
        border-width: 2px;
    }

    .box-row {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    /* Small / Board of Directors */

    .team-container-small {
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: space-evenly;
        padding: 48px 0 48px 0;
        border-style: none none solid none;
        grid-gap: 10px;
    }

    .team-box-small {
        border-style: solid;
        border-width: 2px;
    }

    /* Medium / Social Media / Web Dev */

    .team-container-medium {
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: space-between;
        padding: 0 5% 48px 5%;
        grid-gap: 24px;
        border-style: none none solid none;
    }

    .team-box-medium {
        border-style: solid;
        border-width: 2px;
    }

    .team-image-medium {
        border-radius: 60px;
        border: #fff;
        border-width: 3px;
        height: auto;
        max-width: 75%;
    }
}

@media screen and (max-widtH: 1200px) {
    .about-text {
        padding: 24px 5.5% 24px 5.5%;
    }

    .about-text .about-section-event-photo {
        max-width: 400px;
    }

    .about-nav {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-widtH: 1100px) {
    .about-text {
        padding: 24px 2.5% 24px 2.5%;
    }

    .about-nav {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 920px) {
    .about-text .hugs-logo {
        max-width: 250px;
    }

    .about-text .about-section-event-photo {
        max-width: 350px;
    }

    .box-row {
        flex-direction: column;
    }

    .team-box-large {
        border-style: none none solid none;
    }

    .team-box-large h1 {
        font-size: 3rem;
    }

    .team-container-large {
        padding: 0;
        margin: 0;
    }

    .team-container-medium {
        padding: 0;
        margin: 0;
    }

    .team-box-medium {
        border-style: none none solid none;
    }

    .team-container-small {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .team-box-small {
        padding: 0;
        width: 100%;
        border-style: none none solid none;
    }

    .small-header h1 {
        font-size: 3rem;
    }

    .team-container-medium {
        display: flex;
        flex-direction: column;
    }

    .about-links {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .about-links button {
        width: 100%;
    }
}

@media screen and (max-width: 840px) {
    .about-text img {
        max-width: 300px;
    }

    .about-text {
        padding: 10px;
    }
}

@media screen and (max-width: 800px) {
    .team-box-large {
        flex-direction: column;
    }
}

@media screen and (max-width: 780px) {
    .about-text img {
        max-width: 250px;
    }

    .about-text p {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 720px) {
    .about-text img {
        max-width: 200px;
    }
}

@media screen and (max-width: 680px) {
    .team-container-small {
        grid-template-columns: repeat(1, auto);
    }
}

@media screen and (max-width: 650px) {
    .about-text .hugs-logo {
        max-width: 200px;
    }

    .about-text .about-section-event-photo {
        max-width: 250px;
    }
}

@media screen and (max-width: 580px) {
    .about-text img {
        max-width: 150px;
    }

    .about-nav {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .about-links {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .about-nav button {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .team-image-large {
        max-width: 350px;
    }

    .team-image-medium {
        max-width: 350px;
    }
    
    .about-text p {
        font-size: 1.1rem;
    }

    .team-box-large p {
        font-size: 1.1rem;
    }

    .team-box-large h1 {
        font-size: 2.6rem;
    }

    .small-header h1 {
        font-size: 2.6rem;
    }

    .join-container {
        margin: 48px 5% 48px 5%;
    }
}

@media screen and (max-width: 420px) {
    .team-image-large {
        max-width: 300px;
    }

    .team-image-medium {
        max-width: 300px;
    }
}

@media screen and (max-width: 370px) {
    .team-image-large {
        max-width: 250px;
    }

    .team-image-medium {
        max-width: 250px;
    }

}

@media screen and (max-width: 360px) {
    .team-container-small {
        display: grid;
        grid-template-columns: repeat(1, auto);
    }
}

@media screen and (max-width: 320px) {
    .team-image-large {
        max-width: 200px;
    }

    .team-image-medium {
        max-width: 200px;
    }

}