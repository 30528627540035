.login {
    background: #1e272e; 
    overflow: auto;
}

.login-image {
    flex-basis: 40%;
}

.login-container {
    flex-basis: 50%;
}

/* Login Box */

.login-box h2 {
    font-size: 2.6rem;
}

.error-text {
    color: #d63031;
    margin-top: 10px;
    margin-bottom: 10px;
}

.denied-button:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

.pending-button:hover {
    background: #fdcb6e;
    transition: all 300ms ease-in-out;
}

.approved-button:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.hugs-logo-login {
    max-width: 450px;
}