.login-container {
    padding-bottom: 30px;
    border-style: none none none solid;
    color: #fff;
}

.login-box form {
    margin: 0 25% 0 25%;
}


.login-flexbox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}

.login-buttons {
    margin-top: 24px;
    display: grid;
    flex-direction: column;
    grid-gap: 24px;
}

.login-button {
    width: 60%;
}

.login-toggle-button {
    width: 60%;
}

.login-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-container h1 {
    margin-bottom: 20px;
    font-size: 3.2em;
    text-align: center;
}


.login-container label {
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 20px;
    color: #fff;
    transition: .5s;
    align-self: center;
}

.login-container textarea {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    resize: none;
    text-align: center;
}

.login-container button {
    align-self: center;
}

.login-form {
    vertical-align: baseline;
}

.buttons-container {
    list-style: none;
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    text-align: center;
    clear: left;
    padding: 0;

}

.buttons-container li {
    margin-top: 12px;
}

/* Verification */

.verification-form {
    height: 25%;
    margin: 148px 5% 30px 5%;
    padding-bottom: 30px;
    background: #2d3436;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, #fff 0px 0px 0px 3px;
    border-radius: 50px;
    color: #fff;
}

/* Laptop / Tablet Screens */

@media screen and (max-width: 1700px) {
    .login-button {
        width: 80%;
    }
}

/* Small Screens */

@media screen and (max-width: 1286px) {
    .login-button {
        width: 100%;
    }

    .login-image {
        flex-basis: 30%;
    }

    .login-image img {
        width: 100%;
    }

    .login-box form {
        margin: 0 15% 0 15%;
    }
}

/* Mobile */

@media screen and (max-width: 925px) {

    .login-flexbox {
        display: inline;
    }

    .login-container {
        border-style: none;
        height: 100%;

    }

    .login-container h1 {
        font-size: 3rem;
    }

    .login-box form {
        margin: 0 5% 0 5%;
    }

}

@media screen and (max-width: 420px) {
    .login-container h1 {
        font-size: 2.8rem;
    }
}