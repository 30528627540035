/* Newsletter items */

.newsletter-signup {
    padding: 3%;
    border-style: solid;
}

.newsletter-signup h2 {
    font-size: 2.6rem;
}

.newsletter-signup-items {
    display: grid;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.newsletter-signup h2 {
    margin: 0 0 20px 0;
}

.newsletter-signup button {
    width: 80%;
}

@media screen and (max-width: 1500px) {
    .newsletter-signup h2 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 1300px) {
    .newsletter-signup h2 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 780px) {
    .newsletter-signup h2 {
        font-size: 2.4rem;
    }

    .newsletter-signup {
        border-style: none;
    }

    .newsletter-item .newsletter-signup {
        padding: 0;
    }

}

@media screen and (max-width: 480px) {
    .newsletter-signup h2 {
        font-size: 1.9rem;
    }
}