.event-edit-signin-container {
    margin: 24px 0 24px 0;
    border-style: solid none none none;
    padding-top: 24px;
}

.event-edit-signin-section-header {
    margin: 24px 0 24px 0;
}

.event-edit-signin-information-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 24px 0 24px 0;
}

.event-edit-signin-opt-ins-list {
    margin: 24px 0 24px 0;
}

@media screen and (max-width: 920px) {
    .event-edit-signin-information-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 680px) {
    .event-edit-signin-information-list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}