.affiliate-control-top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
}

.affiliate-control-top-row button {
    width: 100%;
    flex-basis: 33%;
}

.affiliate-control-search-button-container button {
    width: 80%;
}