.join {
    background: #1e272e;
    overflow: auto;
}

/* Prompt */

.join-login-prompt {
    margin: 24px 25% 24px 25%;
    padding: 1%;
    background: #2d3436;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, #fff 0px 0px 0px 3px;
    border-radius: 50px;
    color: #fff;
}

.approved-button:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.denied-button:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

.join-section {
    height: 25%;
    padding: 48px 8.5% 0 8.5%;
    color: #fff;
    border-style: none;
}

.join-section h1 {
    margin: 12px 0 12px 0;
    font-size: 3.4rem;
    align-self: baseline;
    text-align: center;
}

.join-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0 12px 0;
}

.join-nav button {
    flex-basis: 32%;
    width: 100%;
}


/* Form */

.join-form {
    padding: 1%;
    color: #fff;
}

.join-form h1 {
    font-size: 3.4rem;
}

.join-form p { 
    text-align: center;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.join-form button {
    width: 80%;
}

.form-items {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    padding: 0;

}

.middle-row {
    margin: 24px 0 24px 0;
    display: flex;
    justify-content: space-evenly;
}

.textarea-section {
    margin: 24px 15% 24px 15%;
}


@media screen and (max-width: 1500px) {
    .join-section {
        padding: 48px 6.5% 24px 6.5%
    }
}

@media screen and (max-width: 1260px) {
    /* Prompt */

    .join .prompt {
        border-style: none none solid none;
        margin: 24px 0 24px 0;
        padding: 15px 10px 15px 10px;
        text-align: center;
        color: #fff;
    }

    .prompt h1 {
        font-size: 2.8rem;
    }

    .join .prompt button {
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    .join-section {
        padding: 24px 5.5% 24px 5.5%
    }
}

@media screen and (max-width: 1100px) {
    .join-section {
        padding: 24px 2.5% 24px 2.5%
    }
}