.event-archive-confirm {
    margin: 24px 0 24px 0;
}

.event-archive-confirm button {
    width: 40%;
}

.event-archive-input .p-inputtext {
    width: 80%;
}

.event-archive-submit {
    margin: 24px 0 24px 0;
}

.event-archive-submit button {
    width: 80%;
}