/* Dashboard */

.dashboard-row-container {
    display: flex;
    flex-direction: row;
    padding: 0 1% 0 1%;
    list-style: none;
    justify-content: space-between;
    border-style: none none solid none;
    align-items: stretch;
    margin: 0;
}

/* flexbox resizing */

.dashboard-item-infobox {
    flex-basis: 25%;

}

.dashboard-item-applications-container {
    flex-basis: 65%;
    border-style: none none none solid;
}

/* UserInfoBox */

.user-info-box {}

.user-info-box h3 {
    margin: 0;
}

.dashboard h1 {
    font-size: 2.6rem;
}

.user-info-list {
    list-style: none;
    justify-content: space-evenly;
    align-items: left;
    text-align: left;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 24px;
}

.user-info-list h3 {
    font-size: 2rem;
}

.user-info-list p {
    font-size: 1.2rem;
}

/* User Applications */

/* Empty */

.user-applications-container-empty {
    color: #fff;
}

.user-applications-container-empty h1 {
    font-size: 3rem;
}

.no-applications-message {
    margin-top: 150px;
}

/* Filled */

.user-applications-container-active {
    color: #fff;
}

.user-applications-list {
    display: grid;
    list-style: none;
    padding: 0;
}

.application {
    padding: 1%;
    font-size: 1.3rem;
    width: 100%;
    margin-top: 1%;
    border-color: #fff;
    border-style: solid;
    white-space: normal;
    word-wrap: break-word;
}

.application button {}

.application-container {
    border-style: solid;
    border-width: 5px;
    border: #fff;
    width: 100%;
    padding: 24px 0 24px 0;
}

.application-clicked {
    background: #2d3436;
    border: none;
    color: #fff;
    font-size: 1.6rem;
}

/* Colors */

.approved {
    background: #00b894;
}

.pending {
    background: #fdcb6e;
    color: #2d3436;
}

.denied {
    background: #d63031;
}


/* Application Info Handling */

.application-info-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 24px 0 24px 0;
    padding: 0;
    width: 100%;
}

/* Newsletter Prompt */

.newsletter-prompt-container {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 24px;
    border-style: none none solid none;
    color: #fff;
}

.newsletter-prompt-container h1 {
    font-size: 3.6rem;
}

.newsletter-prompt-container .newsletter-signup {
    margin: 24px;
}


/* User Events */

.user-dashboard-events {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 24px;
    border-style: none none solid none;
    color: #fff;
}

/* Full Application View */

.user-review-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    border-style: none none solid none;
}

.user-review-container .review-items {
    grid-template-columns: repeat(3, 1fr);
}

.user-review-container .review-section {
    padding: 0;
    margin: 0;
    border-style: none none solid none;
}


/* Mobile Handling */

@media screen and (max-width: 1200px) {
    .dashboard-row-container {
        display: flex;
        flex-direction: column-reverse;
        padding: 1% 0 1% 0;
        list-style: none;
        justify-content: space-between;
        border-style: none none solid none;
        align-items: stretch;
        margin: 0;
    }

    .dashboard h1 {
        font-size: 2.6rem;
    }

    /* flexbox resizing */

    .dashboard-item-infobox {
        flex-basis: 25%;
        padding: 3%;
    }

    .dashboard-item-applications-container {
        flex-basis: 65%;
        padding: 3%;
        border-style: none none solid none;
    }

    .no-applications-message {
        margin: 0;
    }

    /* UserInfoBox */

    .user-info-box {}

    .user-info-box h3 {
        margin: 0;
    }

    .user-info-list {
        list-style: none;
        justify-content: space-evenly;
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 24px;
        padding: 1%;
        border-style: solid;
        margin-top: 24px;
    }

    .user-info-list h3 {
        font-size: 2rem;
    }

    .user-info-list p {
        font-size: 1.2rem;
    }

    /* Newsletter */

    .newsletter-prompt-container {
        padding: 3%;
    }

    /* Events */

    .user-dashboard-events {
        padding: 3%;
    }

    .application {
        width: 95%;
    }

    .newsletter-prompt-container button {
        width: 80%;
    }
}

@media screen and (max-width: 1000px) {
    .user-info-list {
        border-style: none;
        justify-content: space-between;
    }

    .user-info-list h3 {
        font-size: 1.8rem;
    }

    .user-info-list p {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 780px) {
    .application {
        font-size: 1.3rem;
    }

    .user-info-list {
        grid-template-columns: repeat(1, auto);
    }

    .user-info-list h3 {
        font-size: 1.8rem;
    }

    .user-info-list p {
        font-size: 1.1rem;
    }

    .dashboard h1 {
        font-size: 2.4rem;
    }

    .application-info-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 680px) {
    .application {
        font-size: 1.2rem;
    }

    .user-review-container .review-items {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 480px) {
    .application {
        font-size: 1.1rem;
    }

    .user-info-list h3 {
        font-size: 1.6rem;
    }

    .user-info-list p {
        font-size: 1rem;
    }

    .dashboard h1 {
        font-size: 2.2rem;
    }

    .newsletter-prompt-container button {
        width: 80%;
    }

}