.sponsor-header {
    border-style: none none solid none;
}

.settings-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
}

.settings-control .header {
    font-size: 2.6rem;
    padding: 24px 0 24px 0;
}

.settings-items button {
    width: 32%;
}

@media screen and (max-width: 1500px) {
    .settings-items {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .settings-items {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .settings-items {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 850px) {
    .settings-items {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
        margin: 24px 0 24px 0;
    }

    .settings-items button {
        width: 100%;
        align-self: center;
    }
}