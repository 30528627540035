.testimonial {
}

.testimonial h2 {
    font-family: 'Poppins', sans-serif;
}

.testimonial div {
    flex-basis: 40%;
}

.testimonial img {
    max-height: 500px;
}

@media screen and (max-width: 1200px) {
    .sisterhood-testimonial h1 {
        font-size: 2.6rem;
        padding: 1%;
    }

    .testimonial h2 {
        font-size: 1.4rem;
        padding: 1%;
    }

    .testimonial img {
        max-height: 400px;
    }
}

@media screen and (max-width: 680px) {
    .testimonial img {
        max-height: 350px;
    }

    .testimonial h2 {
        font-size: 1.2rem;
        padding: 1%;
    }
}

@media screen and (max-width: 580px) {
    .sisterhood-testimonial h1 {
        font-size: 2rem;
        padding: 1%;
    }


    .testimonial img {
        max-height: 300px;
    }
}


@media screen and (max-width: 480px) {
    .testimonial h2 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 380px) {
    .testimonial img {
        max-height: 250px;
    }

    .testimonial h2 {
        font-size: .9rem;
    }
}