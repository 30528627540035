.family-member-add-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    padding: 24px 0 24px 0; 
    border-style: none none solid none;
}

.family-member-add-container button {
    height: 80%;
    width: 80%;
    align-self: center;
}

.family-member-add-container div {
    flex-basis: 24%;
}

.family-member-remove-button-container {
    display: flex;
    justify-content: center;
}

.family-member-remove-button-container button {
    align-self: center;
}

@media screen and (max-width: 1200px) {
    .family-member-add-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .family-member-add-container button {
        height: 100%;

    }
}

@media screen and (max-width: 580px) {
    .family-member-add-container {
        grid-template-columns: repeat(1, 1fr);
    }
}