.event-signin {
    padding: 24px 8.5% 24px 8.5%;
}

@media screen and (max-width: 1500px) {
    .event-signin {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .event-signin {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .event-signin {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 780px) {
    .event-signin-container h1 {
        font-size: 2.8rem;
    }
}

@media screen and (max-width: 640px) {
    .event-signin-container h1 {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 550px) {
    .event-signin-container h1 {
        font-size: 2rem;
    }
}