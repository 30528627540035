.employee-navbar-items {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    list-style: none;
    text-align: center;
}

.employee-navbar-items div {
    flex-basis: 24.5%;
}

.employee-navbar-items button {
    width: 100%;
}

.employee-dashboard-nav-application-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.employee-dashboard-nav-application-dropdown-child {
    margin: 12px 0 12px 0;
}

@media screen and (max-width: 1200px) {
    .employee-navbar-items {
        padding: 12px 3% 12px 3%;
    }
}

@media screen and (max-width: 1000px) {
    .employee-navbar-items {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 24px;
    }
}

@media screen and (max-width: 850px) {
    .employee-navbar-items button {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 500px) {
    .employee-navbar-items button {
        font-size: 1.1rem;
    }
}