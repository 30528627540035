/* Section Header */

.events-section-header {
    padding: 24px 0 24px 0;
    border-style: none none solid none;
}

.events-section-header h1 {
    font-size: 3.6rem;
    margin: 0;
}

/* Events Display */

.events-display {
    padding: 48px 0 48px 0;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
    font-family: 'Poppins', sans-serif;
}

.events-display h1 {
    font-size: 3.2rem;
    margin: 0;
}

.events-display-row {
    padding: 24px 8.5% 24px 8.5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
}

.event-info-header {
    font-size: 3rem;
    margin: 24px 0 24px 0;
}

.event-location-header {
    font-size: 2.4rem;
    margin: 0 0 24px 0;
    font-family: 'Poppins', sans-serif;
}

.event-date-header {
    font-size: 2.4rem;
    margin: 24px 0 24px 0;
    font-family: 'Poppins', sans-serif;
}

.events-display-row h1 {
    font-size: 3rem;
    text-align: center;
    align-self: flex-start;
}

.events-display-row h3 {
    font-size: 1.4rem;
    margin: 0;
}

.events-display-row button {
    text-align: center;
    width: 100%;
}

.event-description {
    font-size: 1.2rem;
}

.events-display-info {
    flex-basis: 35%;
    text-align: left;
}

.events-display-image {
    flex-basis: 40%;
    text-align: center;
}

.events-display-image img {
    max-width: 500px;
    border-radius: 8px;
}

.event-signup button {
    width: 80%;
}

/* Carousel */

.main-carousel {
    margin: 24px 0 24px 0;
}

.main-carousel img {
    max-height: 800px;
    border-style: solid;
    align-self: center;
}

.thumbs img {
    max-width: 150px;
    border-style: solid;
}

/* Swiper */
.swiper-main img {
    max-width: 600px;
    vertical-align: middle;
}

@media screen and (max-width: 1500px) {
    .events-display-row {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1300px) {
    .swiper-main img {
        max-width: 1000px;
    }
}

@media screen and (max-width: 1200px) {
    .events-display-row {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1260px) {
    .events-section-header h1 {
        font-size: 3.2rem;
    }

    .events-display h1 {
        font-size: 3rem;
    }

    .events-display-row h1 {
        font-size: 2.8rem;
    }
}


/* Mobile Handling */

@media screen and (max-width: 1200px) {
    .events-display {
        padding: 24px 2.5% 24px 2.5%;
    }

    .events-display-row {
        flex-direction: column-reverse;
    }

    .events-display-image img {
        max-width: 500px;
    }

    .events-display-info {
        margin: 24px 1% 24px 1%;
        width: 90%;
    }

    .event-display-info {
        padding-left: 24px;
    }

    .event-description {
        padding: 0 24px 0 24px;
    }
}

@media screen and (max-width: 810px) {
    .swiper-main img {
        max-width: 700px;
    }
}

@media screen and (max-width: 780px) {
    .events-section-header h1 {
        font-size: 2.8rem;
    }

    .events-display h1 {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 710px) {
    .swiper-main img {
        max-width: 600px;
    }
}

@media screen and (max-width: 610px) {
    .swiper-main img {
        max-width: 500px;
    }
}

@media screen and (max-width: 580px) {

}

@media screen and (max-width: 510px) {
    .events-display-image img {
        max-width: 450px;
    }

    .swiper-main img {
        max-width: 400px;
    }

    .event-description {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 480px) {
    .events-display-image img {
        max-width: 400px;
    }

    .events-section-header h1 {
        font-size: 2.4rem;
    }

    .events-display h1 {
        font-size: 2.6rem;
    }

    .events-display-row h1 {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 420px) {
    .events-display-image img {
        max-width: 350px;
    }

    .events-display h1 {
        font-size: 2.4rem;
    }

    .events-display-row h1 {
        font-size: 2.4rem;
    }

    .event-description {
        font-size: 1.1rem;
    }

    .events-display-row h3 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 410px) {
    .swiper-main img {
        max-width: 300px;
    }
}

@media screen and (max-width: 380px) {
    .event-description {
        font-size: 1rem;
    }
}

@media screen and (max-width: 370px) {
    .events-display-image img {
        max-width: 300px;
    }
}

@media screen and (max-width: 320px) {
    .events-display-image img {
        max-width: 250px;
    }
}

@media screen and (max-width: 310px) {
    .swiper-main img {
        max-width: 200px;
    }
}