/* Navbar */

.employee-navbar {
    padding: 24px 5% 24px 5%;
    display: flex;
    justify-content: space-evenly;
    border-style: none none solid none;
}

.employee-navbar-button {
    flex-basis: 18%;
}

.employee-navbar-button:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

/* Display Container */

.display-container {
    padding: 0 8.5% 24px 8.5%;
}

.display-container .header {
    font-size: 2.6rem;
    padding: 24px 0 24px 0;
}

.view-full-button {
    width: 80%;
}

/* Application Control */

.application-container h2 {
    font-size: 1.8rem;
}

.application-container p {
    font-size: 1.2rem;
}

.assistance-description {
    padding: 0 8.5% 0 8.5%;
}

.description-container {
    margin: 1% 10% 1% 10%;
    padding: 0;
}

/* Search */

.application-search {
    padding: 1%;
}

.search-input {
    margin: 24px 20% 24px 20%;
}

.note-input {
    margin: 24px 20% 24px 20%;
}

.back-button {
    display: flex;
    align-content: flex-start;
    margin: 24px 0 24px 8.5%;
}

.back-button button {
    flex-basis: 40%;
}

.search-item {
    margin: 12px 10% 12px 10%;
    border-style: solid;
}

/* Control Buttons */

.control-items {
    display: flex;
    justify-content: space-between;
    padding: 24px 0 24px 0;
    list-style: none;
    align-items: center;
    text-align: center;
}

.application-edit-header {
    margin: 24px 0 24px 0;
}

.application-edit-nav {
    padding: 24px 8.5% 24px 8.5%;
}

.application-edit-nav-row-1 {
    display: flex;
    justify-content: space-between;
    list-style: none;
    align-items: center;
    text-align: center;
}

.application-edit-nav-row-1 button {
    flex-basis: 32.5%;
    width: 100%;
}

.application-edit-nav-row-2 {
    display: flex;
    justify-content: space-between;
    list-style: none;
    align-items: center;
    text-align: center;
    padding-top: 12px;
}

.application-edit-nav-row-2 button {
    flex-basis: 49.5%;
    width: 100%;
}

.application-control .full-button {
    width: 80%;
    margin: 0 0 1% 0;
}

.application-control-button {
    flex-basis: 24.5%;
}

.application-control-two-button-section {
    display: flex;
    justify-content: space-between;
}

.application-control-half-section-button {
    width: 100%;
    flex-basis: 49.5%;
}

.event-control .control-items button {
    flex-basis: 24.5%;
}

.add-event-button-container {
    width: 100%;
}

.add-event-button-container button {
    width: 80%;
}

.event-edit-options-select-nav {
    display: flex;
    justify-content: space-between;
}

.event-edit-options-select-nav button {
    width: 100%;
    flex-basis: 49%;
}

.attending-display {
    margin: 24px 0 24px 0;
}

/* Legacy Applications */

.legacy-container {
    padding: 24px 0 48px 0;
}

.legacy-control {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    margin: 10px;
}

.legacy-application-info-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display: grid;
}

.legacy-add {
    margin-top: 24px;
}

.legacy-row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    margin: 10px 40px 10px 40px;
}

.legacy-submit-button {
    width: 80%;
    margin: 24px 0 24px 0;
}


/* Application Edit */

.application-edit {
    overflow: visible;
}

.application-header {
    padding: 24px 0 24px 0;
    border-style: none none solid none;
}

.container-header {
    padding: 0 0 24px 0;
    font-size: 3.2rem;
    border-style: none none solid none;
}

.applications-list {
    display: grid;
    list-style: none;
    padding: 0;
}

.employee-review-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    border-style: none none solid none;
}

.employee-review-container .employee-application-review-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.employee-review-container .review-section {
    margin: 0;
    border-style: none none solid none;
    padding: 24px 8.5% 24px 8.5%;
    height: auto;
}

.application-edit-display {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.application-edit-section {
    padding: 24px 10% 24px 10%;
}

.application-edit-section h1 {
    font-size: 3.6rem;
}

.application-edit-section h2 {
    font-size: 2.6rem;
}

.application-edit-section .attachment-text {
    font-size: 1.6rem;
}

.application-edit-section p {
    font-size: 1.2rem;
}

.application-edit-section button {
    width: 80%;
}

.report-info-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 0;
}

.application-edit-item {
    flex-basis: 40%;
}

.details-text {
    margin: 0 10px 10px 10px;
}

.edit-button-row {
    padding: 1% 5% 1% 5%;
    display: flex;
    justify-content: space-evenly;
    border-style: none none solid none;
}

.edit-button-item {
    flex-basis: 20%;
}

.employee-review-container h2 {
    font-size: 2.6rem;
}

.employee-review-container .attachment-text {
    font-size: 1.6rem;
}


.employee-review-container h3 {
    font-size: 2rem;
}

.employee-review-container {
    font-size: 1.6rem;
}

.amount-box {
    margin: 24px 0 24px 0;
}

.status-list {
    display: flex;
    justify-content: space-evenly;
    margin: 24px 0 24px 0;
}

.status-list div {
    flex-basis: 40%;
}

.status-list div button {
    width: 100%;
}

.status-buttons-container {
    margin: 24px 0 24px 0;
}

/* Verify */

.pending-verification {
    padding: 1%;
    border-style: none none solid none;
}

.response-template {
    text-align: left;
    margin: 24px 25% 24px 25%;
}

.response-password-input-container {
    margin: 12px 25% 12px 25%
}

.response-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 12px 0 12px 0;
}

.response-buttons div {
    flex-basis: 40%;
}

.response-buttons button {
    width: 100%;
}

/* Attachments */

.attachments-container {
    list-style: none;
    padding: 1%;
}

.attachment-submit-button {
    width: 80%;
    margin-top: 48px;
}

/* User Info */

.user-info h1 {
    font-size: 3.6rem;
}

.user-info h2 {
    font-size: 2.6rem;
}

/* Events */

.event-edit-row {
    display: flex;
    justify-content: space-between;
}

.event-edit-row label {
    flex-basis: 49%;
    margin: 1%;
}

.event-edit-row div {
    flex-basis: 33%;
}

.event-edit-padding {
    padding: 24px 0 24px 0;
}

.event-control-buttons {
    display: flex;
    justify-content: space-evenly;
}

.event-control-buttons div {
    flex-basis: 19%;
}

.event-control-buttons button {
    width: 100%;
}

.event-control-items {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    list-style: none;
    align-items: center;
    text-align: center;
    border-style: none none solid none;
}

.event-control-items div {
    flex-basis: 24%;
}

.event-control-items button {
    width: 100%;
}

.event-buttons-container {
    display: flex;
    justify-content: space-between;
}

.event-buttons-container button {
    flex-basis: 49%;
}

.event-edit {
    padding: 24px 8.5% 24px 8.5%;
}

.event-time {
    margin: 0 30% 0 30%;
}

/* Edit */

.application-info-list .p-inputtext {
    width: 80%;
}

.yes-button {
    padding: 0.5rem 1rem;
    width: 100%;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.yes-button-selected {
    padding: 0.5rem 1rem;
    width: 100%;
    white-space: nowrap;
    background-color: #00b894;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.no-button {
    padding: 0.5rem 1rem;
    width: 100%;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.no-button-selected {
    padding: 0.5rem 1rem;
    width: 100%;
    white-space: nowrap;
    background-color: #d63031;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

/* Report */

.report-control {
    padding: 24px 0 24px 0;
    display: flex;
    justify-content: space-evenly;
}

.report-control button {
    width: 100%;
    flex-basis: 32%;
}

/* User Control */

.user-control .control-items button {
    flex-basis: 24%;
}

.user-control-buttons {
    display: flex;
    justify-content: space-between;
    margin: 24px 10% 24px 10%;
}

.permission-control-buttons {}

.event-control-items .change-permissions-button {
    width: 40%;
}

.user-control-item {
    flex-basis: 45%;
}

.user-control-item button {
    width: 100%;
}

.response-password-input-container h2 {
    margin: 24px 0 24px 0;
}

.response-password-input-container button {
    margin: 24px 0 24px 0;
}

/* User Control Notes */

.application-container .note-form form {
    margin: 24px 0 24px 0;
}

.application-container .notes-control-list {
    margin: 24px 0 24px 0;
}

.user-info-button {
    margin: 12px 5% 12px 5%;
}

.two-button-section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
}

.one-button-section {
    padding: 12px 0 12px 0;
}

.one-button-section button {
    width: 100%;
}

/* Stats */

.stats-nav {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    margin: 10px 0 10px 0;
    padding: 1% 5% 1% 5%;

}

.stats-remove-section {
    margin: 24px 0 24px 0;
}

.stats-remove-section .p-inputtext {
    width: 60%;
}

.all-stats-button {
    width: 60%;
}

.year-nav {
    margin: 10px 0 10px 0;
    padding-bottom: 10px;
    border-style: none none solid none;
}

.year-nav-month-buttons {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-gap: 10px;
    padding: 1% 5% 1% 5%;
}

.year-all-button {
    width: 80%;
}

.month-nav {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-gap: 10px;
    padding: 1% 5% 1% 5%;
    margin: 10px 0 10px 0;
    border-style: none none solid none;
}

.month-data {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px;
    margin: 10px 0 10px 0;
    border-style: solid none solid none;
}

.day-data {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px;
    margin: 10px 0 10px 0;
}

.stats-content-button {
    background: #273c75;
    border-color: white;
}

.stats-content-button:hover {
    background: #55efc4;
    color: black;
    border-color: white;
    transition: all 300ms ease-in-out;
}

@media screen and (max-width: 1680px) {
    .legacy-application-info-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1600px) {

    .legacy-application-info-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

}

@media screen and (max-width: 1500px) {
    .display-container {
        padding: 0 6.5% 24px 6.5%;
    }

    .mobile-application-edit-control-container {
        padding: 24px 6.5% 24px 6.5%;
    }

    .employee-review-container .review-section {
        padding: 24px 6.5% 24px 6.5%;
    }

    .event-control-items {
        padding: 24px 6.5% 24px 6.5%;
    }

    .back-button {
        margin: 24px 0 24px 6.5%;
    }

    .event-control .control-items button {
        flex-basis: 17%;
    }
}

@media screen and (max-width: 1450px) {
    /* Event Control */

    .mobile-event-control-row-1 {
        display: flex;
        justify-content: space-between;
        padding: 1%;
        list-style: none;
        align-items: center;
        text-align: center;
        border-style: none;
    }

    .mobile-event-control-row-1 button {
        flex-basis: 32%;
    }

    .mobile-event-control-row-2 {
        display: flex;
        justify-content: space-between;
        padding: 1%;
        list-style: none;
        align-items: center;
        text-align: center;
        border-style: none;
    }

    .mobile-event-control-row-2 button {
        flex-basis: 49%;
    }

    .applications-list {
        padding: 0;
    }

    .application-container button {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1300px) {
    .display-container .header {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 1200px) {
    .control-items {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
        padding: 12px;
        border-style: none;
    }

    .back-button {
        margin: 24px 0 24px 5.5%;
    }

    .display-container {
        padding: 0 5.5% 24px 5.5%;
    }

    .display-container .header {
        font-size: 2rem;
    }

    .view-full-application-button button {
        font-size: 1.6rem;
        width: 90%;
    }

    .application-container h2 {
        font-size: 1.6rem;
    }

    .application-header h1 {
        font-size: 2.6rem;
    }

    /* Application Edit Control */

    .mobile-control {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px;
        padding: 0;
    }

    .employee-review-container .review-section {
        padding: 24px 5.5% 24px 5.5%;
    }


    .mobile-control button {
        flex-basis: 32%;
    }

    .container-header {
        margin: 0;
    }

    .mobile-application-edit-control-container {
        padding: 24px 5.5% 24px 5.5%;
    }

    .mobile-application-edit-control-row-1 {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
    }

    .mobile-application-edit-control-row-1 button {
        flex-basis: 32.5%;
    }

    .mobile-application-edit-control-row-2 {
        display: flex;
        justify-content: space-between;
    }

    .mobile-application-edit-control-row-2 button {
        flex-basis: 49.5%;
    }

    .application-edit-section {
        padding: 24px;
    }

    /* Review Container */

    .employee-review-container h3 {
        font-size: 1.6rem;
    }

    .attachment-control-button {
        flex-basis: 30%;
    }

    /* Events */

    .search-item {
        margin: 12px 10% 12px 10%;
    }

    .event-control-items {
        padding: 24px 5.5% 24px 5.5%;
    }

    /* Stats */
    .all-stats-button {
        width: 80%;
    }

    .year-nav {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .month-data {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .employee-review-container .attachment-text {
        font-size: 1.2rem;
    }

    .report-info-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1150px) {
    .application-container h2 {
        font-size: 1.5rem;
    }

    .application-header h1 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 1100px) {
    .display-container {
        padding: 0 2.5% 24px 2.5%;
    }

    .back-button {
        margin: 24px 0 24px 2.5%;
    }

    .mobile-application-edit-control-container {
        padding: 24px 2.5% 24px 2.5%;
    }

    .event-control-items {
        padding: 24px 2.5% 24px 2.5%;
    }

    .employee-review-container .review-section {
        padding: 24px 2.5% 24px 2.5%;
    }

}

@media screen and (max-width: 1050px) {
    .event-control-items {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1000px) {
    .application-container h2 {
        font-size: 1.4rem;
    }

    .application-header h1 {
        font-size: 2.4rem;
    }

    .employee-review-container h3 {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 980px) {

    .application-header h1 {
        font-size: 2.8rem;
    }

    .mobile-control {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .report-info-list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (max-width: 860px) {
    .mobile-event-control-row-1 {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }
}

@media screen and (max-width: 780px) {
    .application-header {
        padding: 12px 0 12px 0;
    }

    .application-header h1 {
        font-size: 2.6rem;
    }

    .application-container h2 {
        font-size: 1.3rem;
    }

    .application-header h1 {
        font-size: 2.3rem;
    }

    .employee-review-container h3 {
        font-size: 1.2rem;
    }

    .employee-review-container .employee-application-review-items {
        grid-template-columns: repeat(2, 1fr);
    }


    /* Stats */
    .stats-nav {
        border-style: none none solid none;
    }

    .year-nav {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .month-nav {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .day-data {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .mobile-application-edit-control-row-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        display: grid;
        grid-gap: 12px;
    }

    .mobile-application-edit-control-row-2 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        display: grid;
        grid-gap: 12px;
    }
}

@media screen and (max-width: 680px) {
    .event-control-items {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .mobile-control-row-1 {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .applications-list-padding {
        padding: 0 12px 0 12px;
    }

    .application-container {
        width: 100%;
        padding: 12px 0 12px 0;
    }

    .application-container h2 {
        font-size: 1.2rem;
    }

    .application-container p {
        font-size: 1.1rem;
    }

    .application-header h1 {
        font-size: 2.2rem;
    }

    /* Legacy */
    .legacy-row {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .legacy-control {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .legacy-submit-button {
        width: 95%;
    }

    /* Stats */
    .stats-nav {
        margin: 10px 5px 10px 5px;
    }

    .month-data {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (max-width: 580px) {
    .employee-review-container .employee-application-review-items {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 480px) {


    .application-container button {
        font-size: 1.1rem;
    }

    .applications-list-padding {
        padding: 0;
    }

    /* Event Control */

    .mobile-event-control-row-2 {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .employee-review-container h2 {
        font-size: 2.3rem;
    }

    .employee-review-container h3 {
        font-size: 1.2rem;
    }

    .review-section p {
        font-size: 1rem;
    }

    /* Stats */

    .year-nav {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

}