.application-preview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 24px 0 24px 0;
    padding: 0;
    width: 100%;
}

.mobile-application-preview {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 24px 0 24px 0;
    padding: 0;
    width: 100%;
}