.report {
    background: #1e272e;
    overflow: auto;
    border: #fff;
    color: #fff;
}

.report-form {
    padding: 48px 8.5% 48px 8.5%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.report-form h1 {
    font-size: 3rem;
    margin: 0;
}

.report-form p {
    font-size: 1.2rem;
}

.report-form button {
    width: 40%;
}

.report-form-content {
    display: flex;
    justify-content: space-evenly;
}

.report-form-item {
    margin: 24px 20% 0 20%;
}

.report-form-submit-button {
    width: 80%;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 8px;
    border: none;
    text-decoration: none;
    font-size: 2.4rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.no:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

.yes:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.incomplete {
    color: #d63031;
}

.completed {
    color: #00b894;
}

@media screen and (max-width: 1260px) {
    .report-form h1 {
        font-size: 3.4rem;
    }

    .report-form-item {
        margin: 24px 10% 0 10%;
    }

    .report-form button {
        width: 90%;
    }
}

@media screen and (max-width: 780px) {
    .report-form {
        padding: 48px 2% 48px 2%;
    }

    .report-form h1 {
        font-size: 3.2rem;
    }

    .report-form-item {
        margin: 24px 2% 0 2%;
    }

    .report-form button {
        width: 100%;
    }

}

@media screen and (max-width: 560px) {
    .report-form-item label {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 480px) {
    .report-form h1 {
        font-size: 3rem;
    }

    .report-form-item label {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 420px) {
    .report-form h1 {
        font-size: 2.8rem;
    }

    .report-form-item label {
        font-size: 1rem;
    }
}