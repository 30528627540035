/* Header items */

.header-container {
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 24px 8.5% 48px 8.5%;
    height: 80%;
    border-color: #fff;
    border-style: none none solid none;
    margin-top: 80px;
}

.header-flexbox {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.infobox-container {
    padding: 1% 5% 1% 5%;
    margin: 12px 48px 12px 48px;
    border-style: solid;
    border-width: 2px;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.infobox-container h1 {
    font-family: 'Poppins', sans-serif;
    padding: 12px 0 12px 0;
    font-size: 3rem;
    margin: 0;
}

.infobox-container p {
    font-size: 1.2rem;
}

/* Text */

.header-text {
    font-family: "Dancing Script", serif;
    font-weight: 600;
    align-self: baseline;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.header-text h1 {
    font-size: 12.5rem;
    color: #fff;
    margin: 0;

}

.header-text h2 {
    font-size: 6rem;
    margin: 0;
    color: #ed1b24;
}

/* Image */

.hugs-logo-image {
    max-width: 400px;
    max-height: 440px;
    height: auto;
    width: 100%;
    align-self: center;
    object-fit: contain;
}

.svg-logo-animation {
    width: 100%;
    height: auto;
    max-width: 400px;
    max-height: 440px;
}

/* Small Screen Handling */

@media screen and (max-width: 1726px) {
    .header-container {
        margin: 100px 0 0 0;
        padding: 0 1% 0 1%;
    }

    /* Text */

    .header-text h1 {
        font-size: 12rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 8rem;
    }

    /* Image */

    .hugs-logo-image {
        padding: 0;
        max-width: 300px;
        height: auto;
        margin: auto;
        align-self: center;
    }

    /* Infobox Container */

    .infobox-container {
        padding: 1% 5% 1% 5%;
        border-style: none;
        box-shadow: none;
    }

    .infobox-container h1 {
        font-size: 3rem;
        margin: 0;
    }

    .infobox-container p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1600px) {
    .header-text h1 {
        font-size: 11rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 7rem;
    }

    .svg-logo-animation {
        max-width: 300px;
        max-height: 330px;
    }
}

@media screen and (max-width: 1450px) {
    /* Text */

    .header-text h1 {
        font-size: 10rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 6rem;
    }

    /* Image */

    .hugs-logo-image {
        max-width: 300px;
        max-height: 330px;
    }

}

/* Small Screen Handling */

@media screen and (max-width: 1260px) {
    /* Text */

    .header-text h1 {
        font-size: 9rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 5rem;
    }

    .hugs-logo-image {
        max-width: 250px;
    }

    .svg-logo-animation {
        max-width: 250px;
    }

}

@media screen and (max-width: 1080px) {
    /* Text */

    .header-text h1 {
        font-size: 8rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 4rem;
    }

    .hugs-logo-image {
        max-width: 220px;
    }

    .svg-logo-animation {
        max-width: 220px;
    }
}

/* Tablet Handling */

@media screen and (max-width: 930px) {
    /* Text */

    .header-text h1 {
        font-size: 7rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 3rem;
    }

    /* Image */

    .hugs-logo-image {
        max-width: 400px;
    }

    .svg-logo-animation {
        max-width: 400px;
        }

    .header-flexbox {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
    }

}

@media screen and (max-width: 780px) {
    .infobox-container h1 {
        font-size: 2.8rem;
        margin: 0;
    }

    .infobox-container p {
        font-size: 1.1rem;
    }
}

/* Mobile Handling */

@media screen and (max-width: 580px) {
    /* Image */

    .image-asset {
        max-width: 300px;
    }

    .infobox-container {
        padding: 1%;
    }

    .infobox-container h1 {
        font-size: 2.6rem;
    }

    .infobox-container p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {
    /* Image */

    .infobox-container h1 {
        font-size: 2.4rem;
    }

    .infobox-container p {
        font-size: 1rem;
    }
}