.attachment-container {
    margin: 24px 0 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.attachment-download {
    list-style: none;
}

.attachment-control-display {
    flex-basis: 80%;
    margin-right: 24px;
}

.attachment-control-button {
    flex-basis: 20%;
    margin-right: 20px;
}

.attachment-control-button a {
    display: inline-block;
    color: #fff;
    text-decoration: none;
}

.attachment-control-delete-button {
    flex-basis: 10%;
    margin-right: 20px;
}

.file-attachment {
    background: none;
    border: #fff;
    border-width: 5px;
    border-style: solid;
    color: #fff;
    padding: 1%;
    font-size: 1.6rem;
    width: 100%;
    transition: all 300ms ease-in-out;
}

.remove-attachment-button {
    width: 100%;
    transition: all 300ms ease-in-out;
}

.attachment-text {
    font-size: 2rem;
    text-align: left;
}

@media screen and (max-width: 1200px) {
    .attachment-text {
        font-size: 1.6rem;
    }

}

@media screen and (max-width: 850px) {
    .attachment-text {
        text-align: center;
    }

    .attachment-container {
        align-content: center;
        margin: 24px 0 24px 0;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .attachment-control-button {
        margin: 0;
    }
}

@media screen and (max-width: 680px) {
    .attachment-text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .attachment-text {
        font-size: 1.4rem;
    }
}

@media screen and (max-widtH: 380px) {
    .attachment-text {
        font-size: 1.3rem;
    }
}