/* Notes */

.notes {
    border-style: none none solid none;
}

.notes h1 {
    font-size: 3.6rem;
}

.note-header {
    margin: 0 0 24px 0;
}

.notes-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;
    padding: 1% 5% 1% 5%;
}

.notes-list li {
    border-style: solid;
    padding: 24px
}

.notes-list h1 {
    font-size: 3rem;
    text-align: left;
}

.notes-list h2 {
    font-size: 2rem;
    text-align: left;
}

.notes-list p {
    font-size: 1.2rem;
    text-align: left;
}

.notes-control-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
}

.notes-control-list li {
    flex-basis: 49%;
}

.notes-control-list li button {
    width: 100%;
}

.note-form-control {
    display: flex;
    justify-content: space-between;
}

.note-form-control button {
    flex-basis: 40%;
}

.note-form button {
    width: 80%;
}

.only-add {
    width: 80%;
    margin: 24px 0 24px 0;
}

/* Deletion */

.note-for-deletion {
    display: flex;
    justify-content: space-between;
}


.delete-note-button {
    margin: 24px;
    height: 50%;
}

@media screen and (max-width: 1500px) {
    .notes-control-list {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .notes-list h1 {
        font-size: 2rem;
        text-align: left;
        margin: 24px;
    }
    
    .notes-list h2 {
        font-size: 1.6rem;
        text-align: left;
        margin-left: 24px;
    }
    
    .notes-list p {
        font-size: 1.2rem;
    }

    .notes-control-list {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .notes-control-list {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 1000px) {
    .notes-list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}