@import url("https://fonts.googleapis.com/css?family=Signika+Negative:300,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* Universal Text Styling */

.app {
  text-align: center;
  background: #1e272e;
  border: #fff;
  color: #fff;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

p {
  font-family: "Signika Negative", sans-serif;
  font-weight: 300;
}

.header {
  font-family: 'Poppins', sans-serif;
}

.page-header {
  margin: 108px 0 0 0;
  border-style: none none solid none;
  color: #fff;
}

.page-header h1 {
  padding: 0 0 24px 0;
  font-size: 3.6rem;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

label {
  font-size: 1.4rem;
  font-family: 'Poppins', sans-serif;
}

.p-inputmask {
  width: 100%;
  padding: 10px 0;
  font-size: 1.4rem;
  color: #282c34;
  color: #fff;
  margin-bottom: 30px;
  resize: none;
  text-align: center;
}

.p-inputtext {
  width: 100%;
  padding: 10px 0;
  font-size: 1.4rem;
  color: #fff;
  background-color: #485460;
  border-style: solid;
  margin: 12px 0 12px 0;
  resize: none;
  text-align: center;
}

.p-inputtextarea {
  width: 100%;
  padding: 10px 0;
  font-size: 1.4rem;
  color: #282c34;
  color: #fff;
  margin-bottom: 30px;
  resize: none;
  text-align: center;
  font-family: "Signika Negative", sans-serif;
  font-size: 1.6rem;
}

/* Colors */

.error {
  color: rgb(238, 80, 80);
}

.success {
  color: #55efc4;
}

.success-message {
    font-family: 'Poppins', sans-serif;
    margin: 24px 0 24px 0;
}

/* Buttons */

button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background: none;
  border: solid;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  transition: all 300ms ease-in-out;
}

.attachment-button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background: none;
  border: solid;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  font-family: 'Poppins', sans-serif;
  transition: all 300ms ease-in-out;
}

/* Button Hovers */

.denied-button:hover {
  background: solid;
  color: black;
  border-color: white;
  background-color: rgb(238, 80, 80);
  transition: all 300ms ease-in-out;
}

.pending-button:hover {
  background: #fdcb6e;
  color: black;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.approved-button:hover {
  background: #55efc4;
  color: black;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.all-button:hover {
  background: #74b9ff;
  color: black;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.search-button:hover {
  background: #a29bfe;
  color: black;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.disabled-button {
  background: #353d43;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.not-approved {
  background: rgb(238, 80, 80);
}

.approved {
  background: #55efc4;
}

@media screen and (max-width: 1260px) {
  .page-header h1 {
    font-size: 3.2rem;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }

  label {
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif;
  }

  .p-inputmask {
    font-size: 1.2rem;
  }

  .p-inputtext {
    font-size: 1.2rem;
  }

  .p-inputtextarea {
    font-size: 1.2rem;
  }

  button {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1080px) {
  button {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 780px) {
  .page-header h1 {
    font-size: 2.8rem;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }

  label {
    font-size: 1.3rem;
    font-family: 'Poppins', sans-serif;
  }

  .p-inputmask {
    font-size: 1.1rem;
  }

  .p-inputtext {
    font-size: 1.1rem;
  }

  .p-inputtextarea {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) {
  .page-header {
    margin: 80px 0 0 0;
    border-style: none none solid none;
    color: #fff;
  }

  .page-header h1 {
    font-size: 2.4rem;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }

  label {
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
  }

  .p-inputmask {
    font-size: 1rem;
  }

  .p-inputtext {
    font-size: 1rem;
  }

  .p-inputtextarea {
    font-size: 1rem;
  }
}

@media screen and (max-width: 420px) {
  .page-header h1 {
    font-size: 3rem;
  }

  button {
    font-size: 1.2rem;
  }
}