.image-nav {
    display: flex;
    justify-content: space-between;
    border-style: none none solid none;
    padding: 24px 8.5% 24px 8.5%;
}

.image-nav button {
    width: 100%;
    flex-basis: 49%;
}

.event-image-change img {
    max-width: 500px;
}

.main-image-change {
    padding: 24px 8.5% 24px 8.5%;
}

.image-add-container {
    padding: 24px 0 24px 0;
}

@media screen and (max-width: 1500px) {
    .image-nav {
        padding: 24px 6.5% 24px 6.5%;
    }

    .main-image-change {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .image-nav {
        padding: 24px 5.5% 24px 5.5%;
    }

    .main-image-change {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .image-nav {
        padding: 24px 2.5% 24px 2.5%;
    }

    .main-image-change {
        padding: 24px 2.5% 24px 2.5%;
    }
}