.logo-link {
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 40px;
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding: 12px 24px 12px 24px;
    background-color: #b33939;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
}

.nav-login-button {
    justify-self: flex-end;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border-style: solid;
    position: absolute;
    top: 20px;
    right: 40px;
    transition: all 300ms ease-in-out;
}

/* For Mobile Dropdown */

.navbar-button {
    border-style: none;
    width: 100%;
}


.logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    font-size: 1.6rem;
}

.hugs-icon {
    height: 68px;
    margin-top: 10px;
    margin-left: 0.2rem;
}


/* Remember to add more to grid-template-columns in the future if adding more items! */
.nav-menu {
    display: grid;
    grid-template-columns: repeat(11, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
}

.nav-links {
    text-decoration: none;
    color: #fff;
    padding: 0.7rem, 1rem;
    font-size: 1.1rem;
    white-space: nowrap;
    transition: all 300ms ease-in-out;

}

a.nav-links {
    padding: 10px;
}

.nav-links i {
    padding-right: 10px;
}

.nav-links:hover {
    background: rgb(238, 80, 80);
    font-size: 1.2rem;
    border-radius: 4px;
    transition: all 300ms ease-in-out;
}

.fa-bars,
.fa-times {
    color: #fff;
}

.menu-icons {
    display: none;
    position: absolute;
    top: 15px;
    right: 40px;
    border-style: solid;
}

/* Handling Different Clicked States for menu-icons to keep padding consistent */

.unclicked {
    padding: 10px 15px 10px 15px;
}

.clicked {
    padding: 10px 16.5px 10px 16.5px;
}

.nav-links-mobile:hover {
    background: rgb(238, 80, 80);
    border-radius: 4px;
    font-size: 105%;
    transition: all 300ms ease-in-out;
}

.dashboard-button {
    justify-self: flex-end;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border-style: solid;
    transition: all 300ms ease-in-out;
}

.dashboard-button:hover {
    background: rgb(238, 80, 80);
    border-radius: 4px;
    transition: all 300ms ease-in-out;
}

.dashboard-button-clicked {
    padding: 0.5rem 1rem;
    width: 100%;
    font-size: 105%;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.dashboard-button-clicked:hover {
    background: rgb(238, 80, 80);
    border-radius: 4px;
    transition: all 300ms ease-in-out;
}

.dropdown-button {
    position: absolute;
    top: 20px;
    right: 40px;
    transition: all 300ms ease-in-out;
}

.dashboard-dropdown {
    list-style: none;
    align-items: center;
    text-align: center;
    padding: 0;
    position: absolute;
    right: 40px;
    top: 60px;
    text-align: right;
}

@media screen and (max-width: 1850px) {
    .nav-links {
        font-size: 1rem;
    }
}

/* Small Screen Handling */

@media screen and (max-width: 1726px) {
    .nav-links {
        font-size: .95rem;
    }

    .nav-login-button {
        font-size: .9rem;
        top: 22px;
    }
}


@media screen and (max-width: 1650px) {
    .NavBarItems {
        z-index: 99;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: rgba(255, 82, 82, .45);
        box-shadow: 0 8px 32px 0 rgba(31, 28, 135, .37);
        backdrop-filter: blur(4px);
        width: 80%;
        height: 100vh;
        position: absolute;
        top: 0;
        right: -110%;
        align-items: stretch;
        padding: 86px 0 30px 0;
        margin: 0;
        z-index: -1;
        /* Animation Handling */
        right: left;
        overflow-y: auto;
        transition-property: all;
        transition-duration: .5s;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    }

    .nav-menu.active {
        right: 0%;
        right: left;
    }

    .nav-links {
        display: block;
        widows: 100%;
        padding: 2rem 0;
        color: #fff;
        font-size: 1.1rem;
    }

    .nav-links:hover {
        background: rgb(238, 80, 80);
        font-size: 100%;
        backdrop-filter: blur(20px);
        border-radius: 4px;
        transition: all 0.2 ease-in-out;
    }

    /* For use for signup / login / user dashboard */

    .highlight-link {
        border-style: solid;
        margin: 0 0 12px 0;
        font-size: 1.2rem;
    }

    .menu-icons {
        display: block;
        width: 5%;
        border-radius: 3px;
    }

    .nav-links-mobile {
        padding: 1.5rem;
        display: block;
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        width: 80%;
        margin: auto;
    }
}

/* Mobile Handling */

@media screen and (max-width: 580px) {
    .navbar {
        height: 50px;
    }

    .menu-icons {
        top: 10px;
    }
    
    .hugs-icon {
        height: 58px;
    }
}