.affiliate-edit-nav {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
}

.affiliate-edit-nav button {
    width: 100%;
    flex-basis: 33%;
}

.affiliate-edit {
    padding: 24px 8.5% 24px 8.5%;
}

.employee-affiliate-review-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 1500px) {
    .affiliate-edit-nav {
        padding: 24px 6.5% 24px 6.5%;
    }
    .affiliate-edit {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .affiliate-edit-nav {
        padding: 24px 5.5% 24px 5.5%;
    }
    .affiliate-edit {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .affiliate-edit-nav {
        padding: 24px 2.5% 24px 2.5%;
    }
    .affiliate-edit {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 780px) {
    .employee-application-review-items {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 580px) {
    .employee-application-review-items {
        grid-template-columns: repeat(1, 1fr);
    }
}