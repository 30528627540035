/* Events */

.events-container {
    color: #fff;
    padding: 24px 8.5% 24px 8.5%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.events-container h1 {
    padding: 12px 0 12px 0;
    font-size: 3rem;
    margin: 0;
}

.events-container p {
    font-size: 1.2rem;
    margin: 12px 0 12px 0;
}

.events-image {
    max-width: 100%;
    max-height: 100%;
}

.events-items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin: 24px 0 24px 0;
}

.events-item {
    flex-basis: 40%;
}

.events-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.events-text {
    width: 80%;
}

.events-info {
    padding: 5% 5% 0 5%;
    border: solid;
}

.events-info h2 {
    margin-top: 0;
    font-size: 2.2rem;
}

.events-info h3 {
    margin-top: 0;
    font-size: 1.6rem;
}

.events-info form {
    margin: 24px 0 24px 0;
    width: 100%;
}

.events-info button {
    width: 90%;
}

.event-names-container {
    text-align: left;
    width: 80%;
}

.event-names-container li {
    padding: 12px 0 12px 0;
}

@media screen and (max-width: 1500px) {
    .events-container {
        padding: 24px 6.5% 24px 6.5%;
    }

    .events-info h2 {
        font-size: 2rem;
    }

    .events-info h3 {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1400px) {
    .events-container button {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1300px) {
    .events-info h2 {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 1200px) {
    .events-container {
        padding: 24px 5.5% 24px 5.5%;
    }

    .events-container button {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1100px) {
    .events-container {
        padding: 24px 2.5% 24px 2.5%;
    }

}

@media screen and (max-width: 980px) {
    .events-items {
        flex-direction: column-reverse;
    }

    .events-image {
        max-width: 500px;
    }

    .events-item {
        width: 90%;
    }
}

@media screen and (max-width: 780px) {
    .events-container h1 {
        font-size: 2.8rem;
    }

    .events-info h2 {
        font-size: 2.4rem;
    }

    .events-info {
        border: none;
        box-shadow: none;
    }
}

@media screen and (max-width: 680px) {
    .events-info {
        padding: 0;
        border: none;
    }
}

@media screen and (max-width: 580px) {
    .events-image {
        max-width: 400px;
    }
}

@media screen and (max-width: 480px) {
    .events-container h1 {
        font-size: 2.2rem;
        margin: 0;
    }

    .events-info h2 {
        margin-top: 0;
        font-size: 1.9rem;
    }

    .events-info h3 {
        margin-top: 0;
        font-size: 1.3rem;
    }

    .events-image {
        max-width: 400px;
    }
}

@media screen and (max-width: 410px) {
    .events-image {
        max-width: 350px;
    }
}

@media screen and (max-width: 360px) {
    .events-image {
        max-width: 300px;
    }
}

@media screen and (max-width: 310px) {
    .events-image {
        max-width: 200px;
    }
}