.employee-permissions-display {
    align-self: center;
}

.permissions-list {
    list-style: none;
    padding: 0;
    text-align: center;
}

.permission-toggle-display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0 12px 0;
}

.permission-name {
    font-size: 2rem;
    margin: 12px 0 12px 0;
    align-self: center;
    font-family: 'Poppins', sans-serif;
}

.permission-buttons {
    flex-basis: 45%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.permission-buttons button {
    align-self: center;
    width: 45%;
}

.update-permissions-button {
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .permission-name {
        font-size: 1.8rem;
    }
}