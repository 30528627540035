.footer {
    width: 100%;
    background-color: #b33939;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: auto;
    padding: 24px 0 24px 0;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.footer-header-text {
    color: #fff;
    text-align: center;
    margin-top: 0px;
    padding-top: 12px;
}

.list-label {
    color: #fff;
    text-align: center;
    font-size: 2.2rem;
    padding-bottom: 12px;
}

.footer-lists {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    align-items: center;
    text-align: center;
    clear: left;
    padding: 0;
    margin: 48px 0 48px 0;
}

.footer-lists li {
    height: 100%;
}

.internal-footer-list {
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: left;
    text-align: center;
    clear: left;
    padding: 0;
}

.footer-links {
    text-decoration: none;
    color: #fff;
    padding: 0.7rem, 1rem;
    white-space: nowrap;
}

.footer-links:hover {
    background: rgb(238, 80, 80);
    border-radius: 4px;
    transition: all 150ms ease-in-out;
}

.bottom-text {
    color: #fff;
    padding-top: 10px;
    margin: 0;
}

@media screen and (max-width: 1000px) {
    .list-label {
        font-size: 1.8rem;
    }

    .footer-header-text {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 480px) {
    .footer-header-text {
        font-size: 1.8rem;
    }

    .list-label {
        font-size: 1rem;
    }

    .internal-footer-list {
        font-size: .8rem;
    }

    .footer p {
        font-size: 1rem;
    }
}