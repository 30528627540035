.sisterhood {
    background: #1e272e;
    overflow: auto;
    border: #fff;
    color: #fff;
}

/* Header */

.sisterhood-header-container {
    margin-top: 80px;
    border-style: none none solid none;
}

.sisterhood-header-intro-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.sisterhood-header {
    display: flex;
    justify-content: space-evenly;
    padding: 1%;
}

.sisterhood-header h1 { 
    color: #fff;
    font-size: 12rem;
    margin: 0;
    font-family: "Dancing Script", serif;
    font-weight: 600;
    align-self: center;
    text-align: center;
}

.sisterhood-header h2 {
    color: #f485ac;
    font-size: 6rem;
    margin: 0;
    font-family: "Dancing Script", serif;
    font-weight: 600;
    align-self: center;
    text-align: center;
}

.sisterhood-header-text-section {
    flex-basis: 80%;
}

.sisterhood-logo-section {
    flex-basis: 19%;
    display: flex;
}

.sisterhood-logo {
    max-width: 150px;
    align-self: center;
    text-align: center;
}

/* Body */

.sisterhood-section {
    padding: 0 1% 0 1%;
    border-style: none none solid none;
}
/* Descriptions */

.sisterhood-descriptions {
    display: flex;
}

.left-description {
    text-align: left;
    padding: 0 1% 0 1%;
}

.description-middle {
    border-style: none solid none solid;
    padding: 1%;
}

.right-description {
    text-align: right;
    padding: 0 1% 0 1%;
}

.founders-image {
    border-radius: 15px;
    max-width: 300px;
}

/* Info Container */

.pink-text {
    color: #fd79a8;
}

/* Info Container */

.info-container {
    padding: 1%;
    margin: 1%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    border-style: solid;
    color: #fff;
}

.mobile-info-container {
    padding: 0 1% 0 1%;
}

.mobile-info-container p {
    font-size: 1rem;
}

.mobile-info-header {
    font-size: 2.6rem;
    margin: 20px 0 20px 0;
}

/* Dotbox containers */

.dotbox-section {
}

.dotbox-container h1 {
    font-size: 3.6rem;
}

.left-aligned {
    text-align: left;
}

.right-aligned {
    text-align: right;
}


.sisterhood-item {
    margin-top: 24px;
    display: flex;
    justify-content: space-evenly;
}

.sisterhood-image {
    max-width: 100%;
    border-radius: 15px;
}

.learn-more {
    padding: 1%;
}

@media screen and (max-width: 1200px) {
    .sisterhood-header h1 {
        font-size: 10rem;
    }

    .sisterhood-header h2 {
        font-size: 5rem;
    }

    .info-container {
        width: 90%;
        border-style: none;
        padding: 1% 5% 1% 5%;
    }

    .info-container p {
        font-size: 1.1rem;
    }

    .sisterhood-section {
        padding: 0;
    }
    .sisterhood-descriptions {
        flex-direction: column;
    }

    .sisterhood-descriptions h1 {
        font-size: 2.6rem;
        margin: 20px 0 20px 0;
    }

    .sisterhood-descriptions p {
        font-size: 1rem;
    }

    .description-middle {
        border-style: none none solid none;
    }

    .left-description {
        border-style: none none solid none;
    }


}

@media screen and (max-width: 860px) {
    .sisterhood-header h2 {
        font-size: 4rem;
    }
}

@media screen and (max-width: 680px) {
    .sisterhood-header h1 {
        font-size: 8rem;
    }

    .sisterhood-header h2 {
        font-size: 3rem;
    }

    .info-container p {
        font-size: 1rem;
    }

    .sisterhood-descriptions h1 {
        font-size: 2.6rem;
    }

    .sisterhood-header-text-section {
        margin: 0 0 24px 0;
    }
}

@media screen and (max-width: 580px) {
    .sisterhood-header h1 {
        font-size: 7rem;
    }

    .sisterhood-header h2 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 580px) {
    .sisterhood-header h1 {
        font-size: 7rem;
    }

    .sisterhood-header h2 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 480px) {
    .sisterhood-header h1 {
        font-size: 5rem;
    }

    .left-description {
        text-align: center;
    }

    .right-description {
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .info-container p {
        font-size: .9rem;
    }

    .mobile-info-header {
        font-size: 2rem;
    }

    .mobile-info-container p {
        font-size: .9rem;
    }

    .sisterhood-descriptions h1 {
        font-size: 2rem;
    }

    .sisterhood-descriptions p {
        font-size: .9rem;
    }

}