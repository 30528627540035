/* Newsletter */

.newsletter-container {
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 24px 8.5% 24px 8.5%;
    height: 100%;
    border-style: none none solid none;
}

.newsletter-container h1 {
    padding: 12px 0 12px 0;
    font-size: 3rem;
    margin: 0;
}

.newsletter-image {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}

/* Container and Flexbox */

.newsletter-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin: 24px 0 24px 0;
}

.newsletter-item {
    flex-basis: 45%;
}

@media screen and (max-width: 1500px) {
    .newsletter-container {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .newsletter-container {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .events-container {
        padding: 24px 2.5% 24px 2.5%;
    }
}

/* Tablet Handling */

@media screen and (max-width: 980px) {
    .newsletter-items {
        flex-direction: column;
    }

    .newsletter-image {
        max-width: 500px;
    }

    .newsletter-item {
        width: 90%;
    }
}

@media screen and (max-width: 780px) {
    .newsletter-container h1 {
        font-size: 2.8rem;
    }

    .newsletter-item {
        width: 100%;
    }
}

@media screen and (max-width: 580px) {
    .newsletter-image {
        max-width: 400px;
    }

    .newsletter-container h1 {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 480px) {
    .newsletter-image {
        max-width: 400px;
    }

    .newsletter-container h1 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 410px) {
    .newsletter-image {
        max-width: 350px;
    }
}

@media screen and (max-width: 360px) {
    .newsletter-image {
        max-width: 300px;
    }
}

@media screen and (max-width: 310px) {
    .newsletter-image {
        max-width: 200px;
    }
}