.sponsors-row {
    color: #fff;
    padding: 24px 8.5% 24px 8.5%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.sponsors-row h1 {
    font-size: 3rem;
    padding: 0;
    margin: 0;
}

.sponsors-container {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    list-style: none;
    padding: 0;
}

.sponsors-item {
    padding-top: 24px;
}

.sponsors-image {
    width: 100%;
    max-width: 180px;
    height: auto;
    margin: auto;
}

.dark-logo {
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
}

.top-sponsor-container {
    padding: 24x 0 24px 0;
}

.top-sponsor-image {
    width: 60%;
}

/* Screen size adjustments */

@media screen and (max-width: 2100px) {
    .sponsors-image {
        max-width: 150px;
    }
}

@media screen and (max-width: 1700px) {
    .sponsors-image {
        max-width: 140px;
    }
}

@media screen and (max-width: 1600px) {
    .sponsors-image {
        max-width: 135px;
    }
}

@media screen and (max-width: 1500px) {
    .sponsors-image {
        max-width: 130px;
    }

    .sponsors-row {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1450px) {
    .sponsors-container {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(4, 1fr);
        list-style: none;
        align-items: center;
        text-align: center;
        clear: left;
        padding: 0;
        grid-gap: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .sponsors-row {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .sponsors-row {
        padding: 24px 2.5% 24px 5.5%;
    }

    .top-sponsor-container {
        padding: 12px 0 12px 0;
    }
}

@media screen and (max-width: 780px) {
    .sponsors-row h1 {
        font-size: 2.8rem;
    }
}

@media screen and (max-width: 680px) {
    .sponsors-container {
        grid-template-columns: repeat(3, 1fr);

    }

    .sponsors-image {
        max-width: 100px;
    }
}

@media screen and (max-width: 580px) {
    .sponsors-row h1 {
        font-size: 2.6rem;
        padding: 12px 0 12px 0;
        margin: 0;
    }

}

@media screen and (max-width: 480px) {
    .sponsors-row h1 {
        font-size: 2.2rem;
        margin: 0;
    }

    .sponsors-container {
        grid-template-columns: repeat(2, 1fr);

    }

}