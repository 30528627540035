.apply {
    background: #1e272e;
    overflow: auto;
}

/* Login Prompt */

.prompt {
    border-style: solid;
    margin: 48px 10% 48px 10%;
    padding: 24px 5% 24px 5%;
    text-align: center;
    color: #fff;
}

.prompt h1 {
    font-size: 3rem;
}

.prompt p {
    font-size: 1.2rem;
    margin: 12px
}

.prompt-buttons {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    padding-left: 0px;
    align-items: center;
    margin: 24px 0 24px 0;
}

.prompt-buttons li {
    flex-basis: 40%;
}

.prompt-buttons button {
    width: 100%;
}

.control-button {
    flex-basis: 18%;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 8px;
    border: none;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.approved-button:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.confirmation-text {
    margin-top: 80px;
}

/* Form */

/* Section */

.application-section {
    height: 25%;
    padding: 48px 8.5% 48px 8.5%;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.application-section h1 {
    margin: 12px 0 12px 0;
    padding: 0;
    font-size: 3rem;
    text-align: center;
}

.application-section p {
    margin: 12px 0 12px 0;
    padding: 0;
    font-size: 1.2rem;
}

.application-section-review {
    padding: 48px 5% 48px 5%;
}

.application-section-review h1 {
    margin: 24px 0 24px 0;
    font-size: 3rem;
    text-align: center;
}

.bleeding-disorder-question {
    font-size: 1.6rem;
    padding: 24px 0 24px 0;
}

.referred-header {
    font-size: 2.4rem;
    padding: 12px 0 12px 0;
}

.application-reason-header {
    font-size: 2.4rem;
    padding: 12px 0 12px 0;
}

.incomplete {
    color: #d63031;
}

.completed {
    color: #00b894;
}

.section-status {
    font-size: 2.2rem;
    padding: 0;
}

.section-items {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    align-items: center;
    text-align: center;
    clear: left;
    grid-gap: 20px;
    margin-top: 12px;
    padding: 0;
}

.two-item-section {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
}

.family-member-information {
    list-style: none;
    padding: 24px 0 24px 0;
    border-style: solid none none none;
}

.family-member-add-information-section {
    padding: 24px 0 24px 0;
}

.section-item {
    padding: 12px;
}

/* Medical Buttons */

.apply-buttons-container {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 12px;
}

.apply-button {
    flex-basis: 40%;
    transition: all 300ms ease-in-out;

}

/* Attachments */

.attachments-list {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.attachment-button-row {
    margin-top: 24px;
    display: flex;
    justify-content: space-evenly;
}

.attachment-button-row div {
    flex-basis: 45%;
}

.attachment-button-row label {
    flex-basis: 45%;
}

.attachment-button-row button {
    width: 100%;
}

.no:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

.yes:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.declined {
    background-color: #d63031;
}

.approved {
    background-color: #00b894;
}

/* Review */

.review-container {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    align-items: center;
    text-align: center;
    clear: left;
    padding: 0;
    grid-gap: 48px;
}

.review-section {
    height: 100%;
    border-style: solid;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.review-section h2 {
    font-size: 2.6rem;
    margin: 12px 0 12px 0;
}

.review-section .section-status {
    font-size: 2rem;
}

.review-section h3 {
    font-size: 1.4rem;
}

.review-section p {
    font-size: 1.2rem;
}

.review-items {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
}

.review-item {
    padding: 1%;
}

/* Submit */

.submit-section {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.submit-section h1 {
    align-self: center;
}

.submit-section h2 {
    align-self: center;
}

.submit-section-text {
    width: 80%;
    padding: 12px 0 12px 0;
    align-self: center;
    transition: all 300ms ease-in-out;
}

/* Icons */

.red-asterisk {
    color: #d63031
}

.green-check {
    color: #00b894;
}

/* Application Submitted */

.application-submitted {
    height: 25%;
    padding: 48px;
    border-style: none none solid none;
    color: #fff;
}

@media screen and (max-width: 1500px) {
    .application-section {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1400px) {
    .application-section-review {
        padding: 48px 2.5% 48px 2.5%;
    }
}

@media screen and (max-width: 1366px) {
    .section-items label {
        font-size: 1.3rem;
    }
}

/* Small Screens */

@media screen and (max-width: 1260px) {

    .prompt {
        border-style: solid;
        margin: 48px 5% 48px 5%;
        padding: 15px 10px 15px 10px;
        text-align: center;
        color: #fff;
    }

    .review-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .review-section {
        display: flex;
        flex-direction: column;
    }

    .section-items {
        grid-template-columns: repeat(2, 1fr);
    }

    .application-section h1 {
        margin: 12px 0 12px 0;
        font-size: 2.8rem;
        text-align: center;
    }

    .application-section button {
        font-size: 1.6rem;
    }

    .application-section-review h1 {
        margin: 12px 0 12px 0;
        font-size: 2.8rem;
        text-align: center;
    }

    .section-status {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 1200px) {
    .application-section {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .application-section {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 1078px) {
    .section-item label {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1000px) {
    .section-item label {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 928px) {
    .section-item label {
        font-size: 1.1rem;
    }

    .p-inputmask {
        font-size: 1rem;
    }

    .p-inputtext {
        font-size: 1rem;
    }

    .p-inputtextarea {
        font-size: 1rem;
    }
}

/* Mobile Handling */

@media screen and (max-width: 850px) {
    /* Prompt */

    .prompt {
        border-style: none none solid none;
        margin: 24px 0 24px 0;
        padding: 15px 10px 15px 10px;
        text-align: center;
        color: #fff;
    }

    .prompt-buttons {
        display: grid;
        flex-direction: column;
        justify-content: space-evenly;
        grid-gap: 24px;

    }

    .prompt-buttons li {
        width: 100%;
    }

    .prompt h1 {
        font-size: 2.8rem;
    }

    /* Application Section */

    .application-section {
        padding: 24px 2.5% 24px 2.5%;
    }

    .application-section h1 {
        margin: 12px 0 12px 0;
        font-size: 2.6rem;
        text-align: center;
    }

    .application-section button {
        font-size: 1.4rem;
    }

    .application-section p {
        font-size: 1.1rem;
    }

    .bleeding-disorder-question {
        font-size: 1.6rem;
        margin: 12px 0 12px 0;
    }

    .attachment-button {
        font-size: 1.4rem;
    }

    .uploaded-attachments-header {
        font-size: 2.4rem;
        padding: 12px 0 12px 0;
    }

    .referred-header {
        font-size: 2.4rem;
        padding: 12px 0 12px 0;
    }

    .application-reason-header {
        font-size: 2.4rem;
        padding: 12px 0 12px 0;
    }

    .review-container {
        padding: 0;
    }

    .review-section {
        border-style: none none solid none;
        margin: 0;
        box-shadow: none;
    }

    .review-section h2 {
        font-size: 2.5rem;
    }

    .review-section .section-status {
        font-size: 1.9rem;
    }

    .application-section-review h1 {
        margin: 12px 0 12px 0;
        font-size: 3rem;
        text-align: center;
    }

    .apply-submit-button {
        width: 98%;
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 580px) {
    /* Prompt */

    .prompt h1 {
        font-size: 2.4rem;
    }

    /* Application Section */

    .application-section h1 {
        margin: 12px 0 12px 0;
        font-size: 2.2rem;
        text-align: center;
    }

    .application-reason-header {
        font-size: 2.2rem;
    }

    .bleeding-disorder-question {
        font-size: 1.4rem;
    }

    .uploaded-attachments-header {
        font-size: 2rem;
    }

    .referred-header {
        font-size: 2rem;
        padding: 12px 0 12px 0;
    }

    .application-reason-header {
        font-size: 2.2em;
        padding: 12px 0 12px 0;
    }

    .section-items {
        grid-template-columns: repeat(1, 1fr);
    }

    .two-item-section {
        grid-template-columns: repeat(1, 1fr);
    }

    .attachment-button {
        font-size: 1.2rem;
    }

    .review-section {
        border-style: none none solid none;
        margin: 0;
    }

    .review-section h2 {
        font-size: 2.4rem;
    }

    .review-section .section-status {
        font-size: 1.8rem;
    }

    .review-section h3 {
        font-size: 1.3rem;
    }

    .review-section p {
        font-size: 1rem;
    }

    .application-section-review h1 {
        margin: 12px 0 12px 0;
        font-size: 2.2rem;
        text-align: center;
    }

    .apply-submit-button {
        width: 98%;
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 480px) {
    /* Prompt */

    .prompt h1 {
        font-size: 2.2rem;
    }

    /* Application Section */

    .application-section h1 {
        margin: 12px 0 12px 0;
        font-size: 2rem;
        text-align: center;
    }

    .application-reason-header {
        font-size: 2rem;
    }

    .bleeding-disorder-question {
        font-size: 1.2rem;
    }

    .uploaded-attachments-header {
        font-size: 1.8rem;
    }

    .referred-header {
        font-size: 1.6rem;
        padding: 12px 0 12px 0;
    }

    .application-reason-header {
        font-size: 2em;
        padding: 12px 0 12px 0;
    }

    .section-items {
        grid-template-columns: repeat(1, 1fr);
    }

    .two-item-section {
        grid-template-columns: repeat(1, 1fr);
    }

    .attachment-button {
        font-size: 1.2rem;
    }

    .review-section {
        border-style: none none solid none;
        margin: 0;
    }

    .review-section h2 {
        font-size: 2.2em;
    }

    .review-section .section-status {
        font-size: 1.6rem;
    }

    .review-section h3 {
        font-size: 1.2rem;
    }

    .review-section p {
        font-size: 1rem;
    }

    .application-section-review h1 {
        margin: 12px 0 12px 0;
        font-size: 2rem;
        text-align: center;
    }

    .apply-submit-button {
        width: 98%;
        font-size: 2rem;
    }
}

@media screen and (max-width: 380px) {
    .application-section-review h1 {
        font-size: 2.8rem;
    }
}