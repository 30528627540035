.event-signin-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.event-signin-nav button {
    width: 80%;
    margin: 12px 0 12px 0;
    flex-basis: 49%;
}

.event-signin-form-section {
    margin: 12px 0 12px 0;
}

.event-signin-form-section label {
    margin: 12px 0 12px 0;
}

.event-signin-form-section .apply-buttons-container {
    margin: 12px 0 12px 0;
}

.event-signin-form-section-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.event-signin-form-section .submit-button {
    width: 80%;
}

.event-signin-attending-list table {
    width: 100%;
    margin-top: 24px;
}

.event-signin-attending-list th {
    font-size: 2rem;
}

.event-signin-attending-list tr {
    font-size: 1.6rem;
    border-spacing: 12px;
}

@media screen and (max-width: 900px) {
    .event-signin-form-section-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .event-signin-nav {
        flex-direction: column;
        align-items: center;
    }

    .even-signin-nav button {
        width: 100%;
    }
}

@media screen and (max-width: 580px) {
    .event-signin-form-section-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}