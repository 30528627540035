@import url(https://fonts.googleapis.com/css?family=Signika+Negative:300,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Universal Text Styling */

.app {
  text-align: center;
  background: #1e272e;
  border: #fff;
  color: #fff;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

p {
  font-family: "Signika Negative", sans-serif;
  font-weight: 300;
}

.header {
  font-family: 'Poppins', sans-serif;
}

.page-header {
  margin: 108px 0 0 0;
  border-style: none none solid none;
  color: #fff;
}

.page-header h1 {
  padding: 0 0 24px 0;
  font-size: 3.6rem;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

label {
  font-size: 1.4rem;
  font-family: 'Poppins', sans-serif;
}

.p-inputmask {
  width: 100%;
  padding: 10px 0;
  font-size: 1.4rem;
  color: #282c34;
  color: #fff;
  margin-bottom: 30px;
  resize: none;
  text-align: center;
}

.p-inputtext {
  width: 100%;
  padding: 10px 0;
  font-size: 1.4rem;
  color: #fff;
  background-color: #485460;
  border-style: solid;
  margin: 12px 0 12px 0;
  resize: none;
  text-align: center;
}

.p-inputtextarea {
  width: 100%;
  padding: 10px 0;
  font-size: 1.4rem;
  color: #282c34;
  color: #fff;
  margin-bottom: 30px;
  resize: none;
  text-align: center;
  font-family: "Signika Negative", sans-serif;
  font-size: 1.6rem;
}

/* Colors */

.error {
  color: rgb(238, 80, 80);
}

.success {
  color: #55efc4;
}

.success-message {
    font-family: 'Poppins', sans-serif;
    margin: 24px 0 24px 0;
}

/* Buttons */

button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background: none;
  border: solid;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  transition: all 300ms ease-in-out;
}

.attachment-button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background: none;
  border: solid;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  font-family: 'Poppins', sans-serif;
  transition: all 300ms ease-in-out;
}

/* Button Hovers */

.denied-button:hover {
  background: solid;
  color: black;
  border-color: white;
  background-color: rgb(238, 80, 80);
  transition: all 300ms ease-in-out;
}

.pending-button:hover {
  background: #fdcb6e;
  color: black;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.approved-button:hover {
  background: #55efc4;
  color: black;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.all-button:hover {
  background: #74b9ff;
  color: black;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.search-button:hover {
  background: #a29bfe;
  color: black;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.disabled-button {
  background: #353d43;
  border-color: white;
  transition: all 300ms ease-in-out;
}

.not-approved {
  background: rgb(238, 80, 80);
}

.approved {
  background: #55efc4;
}

@media screen and (max-width: 1260px) {
  .page-header h1 {
    font-size: 3.2rem;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }

  label {
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif;
  }

  .p-inputmask {
    font-size: 1.2rem;
  }

  .p-inputtext {
    font-size: 1.2rem;
  }

  .p-inputtextarea {
    font-size: 1.2rem;
  }

  button {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1080px) {
  button {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 780px) {
  .page-header h1 {
    font-size: 2.8rem;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }

  label {
    font-size: 1.3rem;
    font-family: 'Poppins', sans-serif;
  }

  .p-inputmask {
    font-size: 1.1rem;
  }

  .p-inputtext {
    font-size: 1.1rem;
  }

  .p-inputtextarea {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) {
  .page-header {
    margin: 80px 0 0 0;
    border-style: none none solid none;
    color: #fff;
  }

  .page-header h1 {
    font-size: 2.4rem;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }

  label {
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
  }

  .p-inputmask {
    font-size: 1rem;
  }

  .p-inputtext {
    font-size: 1rem;
  }

  .p-inputtextarea {
    font-size: 1rem;
  }
}

@media screen and (max-width: 420px) {
  .page-header h1 {
    font-size: 3rem;
  }

  button {
    font-size: 1.2rem;
  }
}
.home {
    background: #1e272e;
    overflow: hidden;
    overflow-x: hidden;
}

.header {
    font-size: 3rem;
    margin: 0;
}

/* Involvement section */

.get-involved-section {
    color: #fff;
    padding: 24px 8.5% 24px 8.5%;
    height: 100%;
    border-style: none none solid none;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.involved-section-container {
    display: flex;
    justify-content: space-evenly;
}

.involved-buttons {
    flex-basis: 54.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
}

.involved-buttons button {
    width: 80%;
}

.involved-image-container {
    flex-basis: 45%;
}

.involved-image {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}

.get-involved-section h1 {
    padding: 12px 0 12px 0;
}

@media screen and (max-width: 1500px) {
    .get-involved-section {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .get-involved-section {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .get-involved-section {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 930px) {
    .involved-buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px;
        text-align: center;
    }

    .involvement-button {
        width: 100%;
    }


    .involved-section-container {
        flex-direction: column;
    }

    .involved-image {
        max-width: 500px;
        margin-top: 24px;
    }
}

@media screen and (max-width: 780px) {
    .get-involved-section .header {
        font-size: 2.8rem;
    }
}

@media screen and (max-width: 580px) {
    .involved-image {
        max-width: 400px;
    }
}

@media screen and (max-width: 480px) {
    .involved-buttons {
        grid-template-columns: repeat(1, 1fr);
    }

    .get-involved-section .header {
        font-size: 2.2rem;
    }

    .involved-image {
        max-width: 350px;
    }
}
/* Header items */

.header-container {
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 24px 8.5% 48px 8.5%;
    height: 80%;
    border-color: #fff;
    border-style: none none solid none;
    margin-top: 80px;
}

.header-flexbox {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.infobox-container {
    padding: 1% 5% 1% 5%;
    margin: 12px 48px 12px 48px;
    border-style: solid;
    border-width: 2px;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.infobox-container h1 {
    font-family: 'Poppins', sans-serif;
    padding: 12px 0 12px 0;
    font-size: 3rem;
    margin: 0;
}

.infobox-container p {
    font-size: 1.2rem;
}

/* Text */

.header-text {
    font-family: "Dancing Script", serif;
    font-weight: 600;
    align-self: baseline;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.header-text h1 {
    font-size: 12.5rem;
    color: #fff;
    margin: 0;

}

.header-text h2 {
    font-size: 6rem;
    margin: 0;
    color: #ed1b24;
}

/* Image */

.hugs-logo-image {
    max-width: 400px;
    max-height: 440px;
    height: auto;
    width: 100%;
    align-self: center;
    object-fit: contain;
}

.svg-logo-animation {
    width: 100%;
    height: auto;
    max-width: 400px;
    max-height: 440px;
}

/* Small Screen Handling */

@media screen and (max-width: 1726px) {
    .header-container {
        margin: 100px 0 0 0;
        padding: 0 1% 0 1%;
    }

    /* Text */

    .header-text h1 {
        font-size: 12rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 8rem;
    }

    /* Image */

    .hugs-logo-image {
        padding: 0;
        max-width: 300px;
        height: auto;
        margin: auto;
        align-self: center;
    }

    /* Infobox Container */

    .infobox-container {
        padding: 1% 5% 1% 5%;
        border-style: none;
        box-shadow: none;
    }

    .infobox-container h1 {
        font-size: 3rem;
        margin: 0;
    }

    .infobox-container p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1600px) {
    .header-text h1 {
        font-size: 11rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 7rem;
    }

    .svg-logo-animation {
        max-width: 300px;
        max-height: 330px;
    }
}

@media screen and (max-width: 1450px) {
    /* Text */

    .header-text h1 {
        font-size: 10rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 6rem;
    }

    /* Image */

    .hugs-logo-image {
        max-width: 300px;
        max-height: 330px;
    }

}

/* Small Screen Handling */

@media screen and (max-width: 1260px) {
    /* Text */

    .header-text h1 {
        font-size: 9rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 5rem;
    }

    .hugs-logo-image {
        max-width: 250px;
    }

    .svg-logo-animation {
        max-width: 250px;
    }

}

@media screen and (max-width: 1080px) {
    /* Text */

    .header-text h1 {
        font-size: 8rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 4rem;
    }

    .hugs-logo-image {
        max-width: 220px;
    }

    .svg-logo-animation {
        max-width: 220px;
    }
}

/* Tablet Handling */

@media screen and (max-width: 930px) {
    /* Text */

    .header-text h1 {
        font-size: 7rem;
        color: #fff;
    }

    .header-text h2 {
        font-size: 3rem;
    }

    /* Image */

    .hugs-logo-image {
        max-width: 400px;
    }

    .svg-logo-animation {
        max-width: 400px;
        }

    .header-flexbox {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
    }

}

@media screen and (max-width: 780px) {
    .infobox-container h1 {
        font-size: 2.8rem;
        margin: 0;
    }

    .infobox-container p {
        font-size: 1.1rem;
    }
}

/* Mobile Handling */

@media screen and (max-width: 580px) {
    /* Image */

    .image-asset {
        max-width: 300px;
    }

    .infobox-container {
        padding: 1%;
    }

    .infobox-container h1 {
        font-size: 2.6rem;
    }

    .infobox-container p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {
    /* Image */

    .infobox-container h1 {
        font-size: 2.4rem;
    }

    .infobox-container p {
        font-size: 1rem;
    }
}
/* Newsletter items */

.newsletter-signup {
    padding: 3%;
    border-style: solid;
}

.newsletter-signup h2 {
    font-size: 2.6rem;
}

.newsletter-signup-items {
    display: grid;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.newsletter-signup h2 {
    margin: 0 0 20px 0;
}

.newsletter-signup button {
    width: 80%;
}

@media screen and (max-width: 1500px) {
    .newsletter-signup h2 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 1300px) {
    .newsletter-signup h2 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 780px) {
    .newsletter-signup h2 {
        font-size: 2.4rem;
    }

    .newsletter-signup {
        border-style: none;
    }

    .newsletter-item .newsletter-signup {
        padding: 0;
    }

}

@media screen and (max-width: 480px) {
    .newsletter-signup h2 {
        font-size: 1.9rem;
    }
}
/* Newsletter */

.newsletter-container {
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 24px 8.5% 24px 8.5%;
    height: 100%;
    border-style: none none solid none;
}

.newsletter-container h1 {
    padding: 12px 0 12px 0;
    font-size: 3rem;
    margin: 0;
}

.newsletter-image {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}

/* Container and Flexbox */

.newsletter-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin: 24px 0 24px 0;
}

.newsletter-item {
    flex-basis: 45%;
}

@media screen and (max-width: 1500px) {
    .newsletter-container {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .newsletter-container {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .events-container {
        padding: 24px 2.5% 24px 2.5%;
    }
}

/* Tablet Handling */

@media screen and (max-width: 980px) {
    .newsletter-items {
        flex-direction: column;
    }

    .newsletter-image {
        max-width: 500px;
    }

    .newsletter-item {
        width: 90%;
    }
}

@media screen and (max-width: 780px) {
    .newsletter-container h1 {
        font-size: 2.8rem;
    }

    .newsletter-item {
        width: 100%;
    }
}

@media screen and (max-width: 580px) {
    .newsletter-image {
        max-width: 400px;
    }

    .newsletter-container h1 {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 480px) {
    .newsletter-image {
        max-width: 400px;
    }

    .newsletter-container h1 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 410px) {
    .newsletter-image {
        max-width: 350px;
    }
}

@media screen and (max-width: 360px) {
    .newsletter-image {
        max-width: 300px;
    }
}

@media screen and (max-width: 310px) {
    .newsletter-image {
        max-width: 200px;
    }
}
/* Events */

.events-container {
    color: #fff;
    padding: 24px 8.5% 24px 8.5%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.events-container h1 {
    padding: 12px 0 12px 0;
    font-size: 3rem;
    margin: 0;
}

.events-container p {
    font-size: 1.2rem;
    margin: 12px 0 12px 0;
}

.events-image {
    max-width: 100%;
    max-height: 100%;
}

.events-items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin: 24px 0 24px 0;
}

.events-item {
    flex-basis: 40%;
}

.events-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.events-text {
    width: 80%;
}

.events-info {
    padding: 5% 5% 0 5%;
    border: solid;
}

.events-info h2 {
    margin-top: 0;
    font-size: 2.2rem;
}

.events-info h3 {
    margin-top: 0;
    font-size: 1.6rem;
}

.events-info form {
    margin: 24px 0 24px 0;
    width: 100%;
}

.events-info button {
    width: 90%;
}

.event-names-container {
    text-align: left;
    width: 80%;
}

.event-names-container li {
    padding: 12px 0 12px 0;
}

@media screen and (max-width: 1500px) {
    .events-container {
        padding: 24px 6.5% 24px 6.5%;
    }

    .events-info h2 {
        font-size: 2rem;
    }

    .events-info h3 {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1400px) {
    .events-container button {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1300px) {
    .events-info h2 {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 1200px) {
    .events-container {
        padding: 24px 5.5% 24px 5.5%;
    }

    .events-container button {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1100px) {
    .events-container {
        padding: 24px 2.5% 24px 2.5%;
    }

}

@media screen and (max-width: 980px) {
    .events-items {
        flex-direction: column-reverse;
    }

    .events-image {
        max-width: 500px;
    }

    .events-item {
        width: 90%;
    }
}

@media screen and (max-width: 780px) {
    .events-container h1 {
        font-size: 2.8rem;
    }

    .events-info h2 {
        font-size: 2.4rem;
    }

    .events-info {
        border: none;
        box-shadow: none;
    }
}

@media screen and (max-width: 680px) {
    .events-info {
        padding: 0;
        border: none;
    }
}

@media screen and (max-width: 580px) {
    .events-image {
        max-width: 400px;
    }
}

@media screen and (max-width: 480px) {
    .events-container h1 {
        font-size: 2.2rem;
        margin: 0;
    }

    .events-info h2 {
        margin-top: 0;
        font-size: 1.9rem;
    }

    .events-info h3 {
        margin-top: 0;
        font-size: 1.3rem;
    }

    .events-image {
        max-width: 400px;
    }
}

@media screen and (max-width: 410px) {
    .events-image {
        max-width: 350px;
    }
}

@media screen and (max-width: 360px) {
    .events-image {
        max-width: 300px;
    }
}

@media screen and (max-width: 310px) {
    .events-image {
        max-width: 200px;
    }
}
.sponsors-row {
    color: #fff;
    padding: 24px 8.5% 24px 8.5%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.sponsors-row h1 {
    font-size: 3rem;
    padding: 0;
    margin: 0;
}

.sponsors-container {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    list-style: none;
    padding: 0;
}

.sponsors-item {
    padding-top: 24px;
}

.sponsors-image {
    width: 100%;
    max-width: 180px;
    height: auto;
    margin: auto;
}

.dark-logo {
    -webkit-filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
            filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
}

.top-sponsor-container {
    padding: 24x 0 24px 0;
}

.top-sponsor-image {
    width: 60%;
}

/* Screen size adjustments */

@media screen and (max-width: 2100px) {
    .sponsors-image {
        max-width: 150px;
    }
}

@media screen and (max-width: 1700px) {
    .sponsors-image {
        max-width: 140px;
    }
}

@media screen and (max-width: 1600px) {
    .sponsors-image {
        max-width: 135px;
    }
}

@media screen and (max-width: 1500px) {
    .sponsors-image {
        max-width: 130px;
    }

    .sponsors-row {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1450px) {
    .sponsors-container {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(4, 1fr);
        list-style: none;
        align-items: center;
        text-align: center;
        clear: left;
        padding: 0;
        grid-gap: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .sponsors-row {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .sponsors-row {
        padding: 24px 2.5% 24px 5.5%;
    }

    .top-sponsor-container {
        padding: 12px 0 12px 0;
    }
}

@media screen and (max-width: 780px) {
    .sponsors-row h1 {
        font-size: 2.8rem;
    }
}

@media screen and (max-width: 680px) {
    .sponsors-container {
        grid-template-columns: repeat(3, 1fr);

    }

    .sponsors-image {
        max-width: 100px;
    }
}

@media screen and (max-width: 580px) {
    .sponsors-row h1 {
        font-size: 2.6rem;
        padding: 12px 0 12px 0;
        margin: 0;
    }

}

@media screen and (max-width: 480px) {
    .sponsors-row h1 {
        font-size: 2.2rem;
        margin: 0;
    }

    .sponsors-container {
        grid-template-columns: repeat(2, 1fr);

    }

}
.logo-link {
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 40px;
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding: 12px 24px 12px 24px;
    background-color: #b33939;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    position: fixed;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 2;
}

.nav-login-button {
    justify-self: flex-end;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border-style: solid;
    position: absolute;
    top: 20px;
    right: 40px;
    transition: all 300ms ease-in-out;
}

/* For Mobile Dropdown */

.navbar-button {
    border-style: none;
    width: 100%;
}


.logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    font-size: 1.6rem;
}

.hugs-icon {
    height: 68px;
    margin-top: 10px;
    margin-left: 0.2rem;
}


/* Remember to add more to grid-template-columns in the future if adding more items! */
.nav-menu {
    display: grid;
    grid-template-columns: repeat(11, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
}

.nav-links {
    text-decoration: none;
    color: #fff;
    padding: 0.7rem, 1rem;
    font-size: 1.1rem;
    white-space: nowrap;
    transition: all 300ms ease-in-out;

}

a.nav-links {
    padding: 10px;
}

.nav-links i {
    padding-right: 10px;
}

.nav-links:hover {
    background: rgb(238, 80, 80);
    font-size: 1.2rem;
    border-radius: 4px;
    transition: all 300ms ease-in-out;
}

.fa-bars,
.fa-times {
    color: #fff;
}

.menu-icons {
    display: none;
    position: absolute;
    top: 15px;
    right: 40px;
    border-style: solid;
}

/* Handling Different Clicked States for menu-icons to keep padding consistent */

.unclicked {
    padding: 10px 15px 10px 15px;
}

.clicked {
    padding: 10px 16.5px 10px 16.5px;
}

.nav-links-mobile:hover {
    background: rgb(238, 80, 80);
    border-radius: 4px;
    font-size: 105%;
    transition: all 300ms ease-in-out;
}

.dashboard-button {
    justify-self: flex-end;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border-style: solid;
    transition: all 300ms ease-in-out;
}

.dashboard-button:hover {
    background: rgb(238, 80, 80);
    border-radius: 4px;
    transition: all 300ms ease-in-out;
}

.dashboard-button-clicked {
    padding: 0.5rem 1rem;
    width: 100%;
    font-size: 105%;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.dashboard-button-clicked:hover {
    background: rgb(238, 80, 80);
    border-radius: 4px;
    transition: all 300ms ease-in-out;
}

.dropdown-button {
    position: absolute;
    top: 20px;
    right: 40px;
    transition: all 300ms ease-in-out;
}

.dashboard-dropdown {
    list-style: none;
    align-items: center;
    text-align: center;
    padding: 0;
    position: absolute;
    right: 40px;
    top: 60px;
    text-align: right;
}

@media screen and (max-width: 1850px) {
    .nav-links {
        font-size: 1rem;
    }
}

/* Small Screen Handling */

@media screen and (max-width: 1726px) {
    .nav-links {
        font-size: .95rem;
    }

    .nav-login-button {
        font-size: .9rem;
        top: 22px;
    }
}


@media screen and (max-width: 1650px) {
    .NavBarItems {
        z-index: 99;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: rgba(255, 82, 82, .45);
        box-shadow: 0 8px 32px 0 rgba(31, 28, 135, .37);
        -webkit-backdrop-filter: blur(4px);
                backdrop-filter: blur(4px);
        width: 80%;
        height: 100vh;
        position: absolute;
        top: 0;
        right: -110%;
        align-items: stretch;
        padding: 86px 0 30px 0;
        margin: 0;
        z-index: -1;
        /* Animation Handling */
        right: left;
        overflow-y: auto;
        transition-property: all;
        transition-duration: .5s;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    }

    .nav-menu.active {
        right: 0%;
        right: left;
    }

    .nav-links {
        display: block;
        widows: 100%;
        padding: 2rem 0;
        color: #fff;
        font-size: 1.1rem;
    }

    .nav-links:hover {
        background: rgb(238, 80, 80);
        font-size: 100%;
        -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);
        border-radius: 4px;
        transition: all 0.2 ease-in-out;
    }

    /* For use for signup / login / user dashboard */

    .highlight-link {
        border-style: solid;
        margin: 0 0 12px 0;
        font-size: 1.2rem;
    }

    .menu-icons {
        display: block;
        width: 5%;
        border-radius: 3px;
    }

    .nav-links-mobile {
        padding: 1.5rem;
        display: block;
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        width: 80%;
        margin: auto;
    }
}

/* Mobile Handling */

@media screen and (max-width: 580px) {
    .navbar {
        height: 50px;
    }

    .menu-icons {
        top: 10px;
    }
    
    .hugs-icon {
        height: 58px;
    }
}
.footer {
    width: 100%;
    background-color: #b33939;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: auto;
    padding: 24px 0 24px 0;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.footer-header-text {
    color: #fff;
    text-align: center;
    margin-top: 0px;
    padding-top: 12px;
}

.list-label {
    color: #fff;
    text-align: center;
    font-size: 2.2rem;
    padding-bottom: 12px;
}

.footer-lists {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    align-items: center;
    text-align: center;
    clear: left;
    padding: 0;
    margin: 48px 0 48px 0;
}

.footer-lists li {
    height: 100%;
}

.internal-footer-list {
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: left;
    text-align: center;
    clear: left;
    padding: 0;
}

.footer-links {
    text-decoration: none;
    color: #fff;
    padding: 0.7rem, 1rem;
    white-space: nowrap;
}

.footer-links:hover {
    background: rgb(238, 80, 80);
    border-radius: 4px;
    transition: all 150ms ease-in-out;
}

.bottom-text {
    color: #fff;
    padding-top: 10px;
    margin: 0;
}

@media screen and (max-width: 1000px) {
    .list-label {
        font-size: 1.8rem;
    }

    .footer-header-text {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 480px) {
    .footer-header-text {
        font-size: 1.8rem;
    }

    .list-label {
        font-size: 1rem;
    }

    .internal-footer-list {
        font-size: .8rem;
    }

    .footer p {
        font-size: 1rem;
    }
}
.about {
    background: #1e272e;
    overflow: auto;
    border: #fff;
    color: #fff;
}

.about .header {
    margin: 12px 0 12px 0;
}

/* Nav */

.about-nav {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    list-style: none;
    align-items: center;
    text-align: center;
    border-style: none none solid none;
}

.about-nav button {
    flex-basis: 49%;
}

.selected-about-button {
    background: #273c75;
    border-color: white;
}

/* About Us */

.about-text {
    padding: 24px 8.5% 24px 8.5%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.about-text h1 {
    font-size: 3.2rem;
}

.about-text .hugs-logo {
    max-width: 350px;
}

.about-text p {
    text-align: left;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.float-right {
    float: right;
    margin-left: 10px;
}

.float-left {
    float: left;
    margin-left: 10px;
}

.about-text .about-section-event-photo {
    max-width: 500px;
}

/* Get Involved */

.about-links {
    display: flex;
    justify-content: space-between;
    padding: 1% 5% 1% 5%;
    list-style: none;
    align-items: center;
    text-align: center;
}

.about-links div {
    flex-basis: 24%;
}

.about-links button {
    width: 100%;
}

/* Sponsorships */

.sponsorship-text {
    padding: 24px 8.5% 24px 8.5%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.tier-section h1 {
    font-size: 3.2rem;
    padding: 12px 0 12px 0;
}

.sponsorship-type {
    font-size: 3.2rem;
    padding: 12px 0 12px 0;
}

.sponsorship-subtitle {
    font-size: 2.2rem;
    padding: 0 0 12px 0;
}

.sponsorship-text img {
    max-width: 350px;
}

.sponsorship-text p {
    text-align: center;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.tier-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    gap: 20px;
}

.sponsorship-container {
    outline: #fff;
    outline-style: solid;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
}

.sponsorship-container h1 {
    margin: 12px;
}

.sponsorship-container h2 {
    margin: 6px;
}

.sponsorship-container ul {
    list-style-type: circle;
    text-align: left;
}

.sponsorship-container li {
    margin-bottom: 10px;
}

/* Sponsorship tier colors */

.tier-amount {
    color: #2ecc71;
}

.bronze {
    color: #c59d5f;
}

.silver {
    color: #d3d3d3;
}

.gold {
    color: #ffd700;
}

.platinum {
    color: #e5e4e2;
}


/* Meet The Team */

/* .meet-the-team-content {
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
} */

/* Team Large */

.team-container-large {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 8.5% 0 8.5%;
    margin: 0;
}

.title-section {
    display: flex;
    flex-direction: column;
}

.description-section {
    align-self: center;
}

.team-box-large {
    display: flex;
    margin: 24px 0 24px 0;
    padding: 24px;
    border-style: solid;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-box-large-reverse {
    display: flex;
    padding: 24px;
    margin: 24px 0 24px 0;
    border-style: solid;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.row-reverse {
    flex-direction: row-reverse;
}

.team-box-large h1 {
    font-size: 3.2rem;
    margin: 12px 0 12px 0;
}

.team-box-large p {
    font-size: 1.2rem;
    padding: 12px 5% 12px 5%;
}

.team-image-large {
    max-width: 400px;
    border-radius: 80px;
    border: #fff;
    border-width: 3px;
    margin: 12px 0 12px 0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-title-large {
    font-family: 'Poppins', sans-serif;
    font-size: 3.2rem;
    margin: 12px 0 12px 0;
}

.team-subtitle-large {
    font-size: 2.2rem;
    font-family: 'Poppins', sans-serif;
    margin: 12px 0 12px 0;
}

/* Team Medium */

.team-container-medium {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    margin: 0;
}

.team-box-medium {
    border-style: solid;
    color: #fff;
    padding: 0 24px 0 24px;
    flex-basis: 45%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-image-medium {
    border-radius: 60px;
    border: #fff;
    border-width: 3px;
    height: auto;
    margin: 12px 0 12px 0;
    width: 400px;
    height: 400px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-title-medium {
    font-family: 'Poppins', sans-serif;
    font-size: 2.2rem;
    margin: 12px 0 12px 0;
}

.team-subtitle-medium {
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    margin: 12px 0 12px 0;
}

/* Team Small / Board of Directors */

.small-header h1 {
    font-size: 3.2rem;
}

.team-container-small {
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    margin: 0;
}

.team-box-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    justify-content: space-between;
    flex-basis: 20%;
    padding: 0 12px 12px 12px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-image-small {
    border-radius: 15px;
    border: #fff;
    height: auto;
    max-width: 300px;
    margin: 12px 0 12px 0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.team-title-small {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    margin: 12px 0 12px 0;
}

.team-subtitle-small {
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    margin: 12px 0 12px 0;
}

/* Ambassadors Section */


/* Change the flex direction when adding more items later */
.ambassadors-container {
    padding: 24px 8.5% 24px 8.5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-style: none none solid none;
}

.ambassador-box {
    width: 24%;
    border-style: solid;
    align-self: center;
}

.ambassador-image {
    border-radius: 15px;
    width: 50%;
    margin: 12px 0 12px 0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/* Join Section */

.team-join-container {
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 24px 8.5% 48px 8.5%;
    border-style: none none solid none;
}

/* Small Screens */

@media screen and (max-width: 1600px) {
    .team-image-small {
        max-width: 250px;
    }
}

@media screen and (max-widtH: 1500px) {
    .about-text {
        padding: 24px 6.5% 24px 6.5%;
    }
    
    .about-nav {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1374px) {
    .team-container-large {
        display: grid;
        grid-template-columns: repeat(1, auto);
        justify-content: space-evenly;
        padding: 0 5% 48px 5%;
        border-style: none;
    }

    .team-box-large {
        margin-top: 24px;
        padding: 2.5%;
        border-width: 2px;
    }

    .box-row {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    /* Small / Board of Directors */

    .team-container-small {
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: space-evenly;
        padding: 48px 0 48px 0;
        border-style: none none solid none;
        grid-gap: 10px;
    }

    .team-box-small {
        border-style: solid;
        border-width: 2px;
    }

    /* Medium / Social Media / Web Dev */

    .team-container-medium {
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: space-between;
        padding: 0 5% 48px 5%;
        grid-gap: 24px;
        border-style: none none solid none;
    }

    .team-box-medium {
        border-style: solid;
        border-width: 2px;
    }

    .team-image-medium {
        border-radius: 60px;
        border: #fff;
        border-width: 3px;
        height: auto;
        max-width: 75%;
    }
}

@media screen and (max-widtH: 1200px) {
    .about-text {
        padding: 24px 5.5% 24px 5.5%;
    }

    .about-text .about-section-event-photo {
        max-width: 400px;
    }

    .about-nav {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-widtH: 1100px) {
    .about-text {
        padding: 24px 2.5% 24px 2.5%;
    }

    .about-nav {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 920px) {
    .about-text .hugs-logo {
        max-width: 250px;
    }

    .about-text .about-section-event-photo {
        max-width: 350px;
    }

    .box-row {
        flex-direction: column;
    }

    .team-box-large {
        border-style: none none solid none;
    }

    .team-box-large h1 {
        font-size: 3rem;
    }

    .team-container-large {
        padding: 0;
        margin: 0;
    }

    .team-container-medium {
        padding: 0;
        margin: 0;
    }

    .team-box-medium {
        border-style: none none solid none;
    }

    .team-container-small {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .team-box-small {
        padding: 0;
        width: 100%;
        border-style: none none solid none;
    }

    .small-header h1 {
        font-size: 3rem;
    }

    .team-container-medium {
        display: flex;
        flex-direction: column;
    }

    .about-links {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .about-links button {
        width: 100%;
    }
}

@media screen and (max-width: 840px) {
    .about-text img {
        max-width: 300px;
    }

    .about-text {
        padding: 10px;
    }
}

@media screen and (max-width: 800px) {
    .team-box-large {
        flex-direction: column;
    }
}

@media screen and (max-width: 780px) {
    .about-text img {
        max-width: 250px;
    }

    .about-text p {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 720px) {
    .about-text img {
        max-width: 200px;
    }
}

@media screen and (max-width: 680px) {
    .team-container-small {
        grid-template-columns: repeat(1, auto);
    }
}

@media screen and (max-width: 650px) {
    .about-text .hugs-logo {
        max-width: 200px;
    }

    .about-text .about-section-event-photo {
        max-width: 250px;
    }
}

@media screen and (max-width: 580px) {
    .about-text img {
        max-width: 150px;
    }

    .about-nav {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .about-links {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .about-nav button {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .team-image-large {
        max-width: 350px;
    }

    .team-image-medium {
        max-width: 350px;
    }
    
    .about-text p {
        font-size: 1.1rem;
    }

    .team-box-large p {
        font-size: 1.1rem;
    }

    .team-box-large h1 {
        font-size: 2.6rem;
    }

    .small-header h1 {
        font-size: 2.6rem;
    }

    .join-container {
        margin: 48px 5% 48px 5%;
    }
}

@media screen and (max-width: 420px) {
    .team-image-large {
        max-width: 300px;
    }

    .team-image-medium {
        max-width: 300px;
    }
}

@media screen and (max-width: 370px) {
    .team-image-large {
        max-width: 250px;
    }

    .team-image-medium {
        max-width: 250px;
    }

}

@media screen and (max-width: 360px) {
    .team-container-small {
        display: grid;
        grid-template-columns: repeat(1, auto);
    }
}

@media screen and (max-width: 320px) {
    .team-image-large {
        max-width: 200px;
    }

    .team-image-medium {
        max-width: 200px;
    }

}
.apply {
    background: #1e272e;
    overflow: auto;
}

/* Login Prompt */

.prompt {
    border-style: solid;
    margin: 48px 10% 48px 10%;
    padding: 24px 5% 24px 5%;
    text-align: center;
    color: #fff;
}

.prompt h1 {
    font-size: 3rem;
}

.prompt p {
    font-size: 1.2rem;
    margin: 12px
}

.prompt-buttons {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    padding-left: 0px;
    align-items: center;
    margin: 24px 0 24px 0;
}

.prompt-buttons li {
    flex-basis: 40%;
}

.prompt-buttons button {
    width: 100%;
}

.control-button {
    flex-basis: 18%;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 8px;
    border: none;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.approved-button:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.confirmation-text {
    margin-top: 80px;
}

/* Form */

/* Section */

.application-section {
    height: 25%;
    padding: 48px 8.5% 48px 8.5%;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.application-section h1 {
    margin: 12px 0 12px 0;
    padding: 0;
    font-size: 3rem;
    text-align: center;
}

.application-section p {
    margin: 12px 0 12px 0;
    padding: 0;
    font-size: 1.2rem;
}

.application-section-review {
    padding: 48px 5% 48px 5%;
}

.application-section-review h1 {
    margin: 24px 0 24px 0;
    font-size: 3rem;
    text-align: center;
}

.bleeding-disorder-question {
    font-size: 1.6rem;
    padding: 24px 0 24px 0;
}

.referred-header {
    font-size: 2.4rem;
    padding: 12px 0 12px 0;
}

.application-reason-header {
    font-size: 2.4rem;
    padding: 12px 0 12px 0;
}

.incomplete {
    color: #d63031;
}

.completed {
    color: #00b894;
}

.section-status {
    font-size: 2.2rem;
    padding: 0;
}

.section-items {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    align-items: center;
    text-align: center;
    clear: left;
    grid-gap: 20px;
    margin-top: 12px;
    padding: 0;
}

.two-item-section {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
}

.family-member-information {
    list-style: none;
    padding: 24px 0 24px 0;
    border-style: solid none none none;
}

.family-member-add-information-section {
    padding: 24px 0 24px 0;
}

.section-item {
    padding: 12px;
}

/* Medical Buttons */

.apply-buttons-container {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 12px;
}

.apply-button {
    flex-basis: 40%;
    transition: all 300ms ease-in-out;

}

/* Attachments */

.attachments-list {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.attachment-button-row {
    margin-top: 24px;
    display: flex;
    justify-content: space-evenly;
}

.attachment-button-row div {
    flex-basis: 45%;
}

.attachment-button-row label {
    flex-basis: 45%;
}

.attachment-button-row button {
    width: 100%;
}

.no:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

.yes:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.declined {
    background-color: #d63031;
}

.approved {
    background-color: #00b894;
}

/* Review */

.review-container {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    align-items: center;
    text-align: center;
    clear: left;
    padding: 0;
    grid-gap: 48px;
}

.review-section {
    height: 100%;
    border-style: solid;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.review-section h2 {
    font-size: 2.6rem;
    margin: 12px 0 12px 0;
}

.review-section .section-status {
    font-size: 2rem;
}

.review-section h3 {
    font-size: 1.4rem;
}

.review-section p {
    font-size: 1.2rem;
}

.review-items {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
}

.review-item {
    padding: 1%;
}

/* Submit */

.submit-section {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.submit-section h1 {
    align-self: center;
}

.submit-section h2 {
    align-self: center;
}

.submit-section-text {
    width: 80%;
    padding: 12px 0 12px 0;
    align-self: center;
    transition: all 300ms ease-in-out;
}

/* Icons */

.red-asterisk {
    color: #d63031
}

.green-check {
    color: #00b894;
}

/* Application Submitted */

.application-submitted {
    height: 25%;
    padding: 48px;
    border-style: none none solid none;
    color: #fff;
}

@media screen and (max-width: 1500px) {
    .application-section {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1400px) {
    .application-section-review {
        padding: 48px 2.5% 48px 2.5%;
    }
}

@media screen and (max-width: 1366px) {
    .section-items label {
        font-size: 1.3rem;
    }
}

/* Small Screens */

@media screen and (max-width: 1260px) {

    .prompt {
        border-style: solid;
        margin: 48px 5% 48px 5%;
        padding: 15px 10px 15px 10px;
        text-align: center;
        color: #fff;
    }

    .review-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .review-section {
        display: flex;
        flex-direction: column;
    }

    .section-items {
        grid-template-columns: repeat(2, 1fr);
    }

    .application-section h1 {
        margin: 12px 0 12px 0;
        font-size: 2.8rem;
        text-align: center;
    }

    .application-section button {
        font-size: 1.6rem;
    }

    .application-section-review h1 {
        margin: 12px 0 12px 0;
        font-size: 2.8rem;
        text-align: center;
    }

    .section-status {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 1200px) {
    .application-section {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .application-section {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 1078px) {
    .section-item label {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1000px) {
    .section-item label {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 928px) {
    .section-item label {
        font-size: 1.1rem;
    }

    .p-inputmask {
        font-size: 1rem;
    }

    .p-inputtext {
        font-size: 1rem;
    }

    .p-inputtextarea {
        font-size: 1rem;
    }
}

/* Mobile Handling */

@media screen and (max-width: 850px) {
    /* Prompt */

    .prompt {
        border-style: none none solid none;
        margin: 24px 0 24px 0;
        padding: 15px 10px 15px 10px;
        text-align: center;
        color: #fff;
    }

    .prompt-buttons {
        display: grid;
        flex-direction: column;
        justify-content: space-evenly;
        grid-gap: 24px;

    }

    .prompt-buttons li {
        width: 100%;
    }

    .prompt h1 {
        font-size: 2.8rem;
    }

    /* Application Section */

    .application-section {
        padding: 24px 2.5% 24px 2.5%;
    }

    .application-section h1 {
        margin: 12px 0 12px 0;
        font-size: 2.6rem;
        text-align: center;
    }

    .application-section button {
        font-size: 1.4rem;
    }

    .application-section p {
        font-size: 1.1rem;
    }

    .bleeding-disorder-question {
        font-size: 1.6rem;
        margin: 12px 0 12px 0;
    }

    .attachment-button {
        font-size: 1.4rem;
    }

    .uploaded-attachments-header {
        font-size: 2.4rem;
        padding: 12px 0 12px 0;
    }

    .referred-header {
        font-size: 2.4rem;
        padding: 12px 0 12px 0;
    }

    .application-reason-header {
        font-size: 2.4rem;
        padding: 12px 0 12px 0;
    }

    .review-container {
        padding: 0;
    }

    .review-section {
        border-style: none none solid none;
        margin: 0;
        box-shadow: none;
    }

    .review-section h2 {
        font-size: 2.5rem;
    }

    .review-section .section-status {
        font-size: 1.9rem;
    }

    .application-section-review h1 {
        margin: 12px 0 12px 0;
        font-size: 3rem;
        text-align: center;
    }

    .apply-submit-button {
        width: 98%;
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 580px) {
    /* Prompt */

    .prompt h1 {
        font-size: 2.4rem;
    }

    /* Application Section */

    .application-section h1 {
        margin: 12px 0 12px 0;
        font-size: 2.2rem;
        text-align: center;
    }

    .application-reason-header {
        font-size: 2.2rem;
    }

    .bleeding-disorder-question {
        font-size: 1.4rem;
    }

    .uploaded-attachments-header {
        font-size: 2rem;
    }

    .referred-header {
        font-size: 2rem;
        padding: 12px 0 12px 0;
    }

    .application-reason-header {
        font-size: 2.2em;
        padding: 12px 0 12px 0;
    }

    .section-items {
        grid-template-columns: repeat(1, 1fr);
    }

    .two-item-section {
        grid-template-columns: repeat(1, 1fr);
    }

    .attachment-button {
        font-size: 1.2rem;
    }

    .review-section {
        border-style: none none solid none;
        margin: 0;
    }

    .review-section h2 {
        font-size: 2.4rem;
    }

    .review-section .section-status {
        font-size: 1.8rem;
    }

    .review-section h3 {
        font-size: 1.3rem;
    }

    .review-section p {
        font-size: 1rem;
    }

    .application-section-review h1 {
        margin: 12px 0 12px 0;
        font-size: 2.2rem;
        text-align: center;
    }

    .apply-submit-button {
        width: 98%;
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 480px) {
    /* Prompt */

    .prompt h1 {
        font-size: 2.2rem;
    }

    /* Application Section */

    .application-section h1 {
        margin: 12px 0 12px 0;
        font-size: 2rem;
        text-align: center;
    }

    .application-reason-header {
        font-size: 2rem;
    }

    .bleeding-disorder-question {
        font-size: 1.2rem;
    }

    .uploaded-attachments-header {
        font-size: 1.8rem;
    }

    .referred-header {
        font-size: 1.6rem;
        padding: 12px 0 12px 0;
    }

    .application-reason-header {
        font-size: 2em;
        padding: 12px 0 12px 0;
    }

    .section-items {
        grid-template-columns: repeat(1, 1fr);
    }

    .two-item-section {
        grid-template-columns: repeat(1, 1fr);
    }

    .attachment-button {
        font-size: 1.2rem;
    }

    .review-section {
        border-style: none none solid none;
        margin: 0;
    }

    .review-section h2 {
        font-size: 2.2em;
    }

    .review-section .section-status {
        font-size: 1.6rem;
    }

    .review-section h3 {
        font-size: 1.2rem;
    }

    .review-section p {
        font-size: 1rem;
    }

    .application-section-review h1 {
        margin: 12px 0 12px 0;
        font-size: 2rem;
        text-align: center;
    }

    .apply-submit-button {
        width: 98%;
        font-size: 2rem;
    }
}

@media screen and (max-width: 380px) {
    .application-section-review h1 {
        font-size: 2.8rem;
    }
}
.attachment-container {
    margin: 24px 0 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.attachment-download {
    list-style: none;
}

.attachment-control-display {
    flex-basis: 80%;
    margin-right: 24px;
}

.attachment-control-button {
    flex-basis: 20%;
    margin-right: 20px;
}

.attachment-control-button a {
    display: inline-block;
    color: #fff;
    text-decoration: none;
}

.attachment-control-delete-button {
    flex-basis: 10%;
    margin-right: 20px;
}

.file-attachment {
    background: none;
    border: #fff;
    border-width: 5px;
    border-style: solid;
    color: #fff;
    padding: 1%;
    font-size: 1.6rem;
    width: 100%;
    transition: all 300ms ease-in-out;
}

.remove-attachment-button {
    width: 100%;
    transition: all 300ms ease-in-out;
}

.attachment-text {
    font-size: 2rem;
    text-align: left;
}

@media screen and (max-width: 1200px) {
    .attachment-text {
        font-size: 1.6rem;
    }

}

@media screen and (max-width: 850px) {
    .attachment-text {
        text-align: center;
    }

    .attachment-container {
        align-content: center;
        margin: 24px 0 24px 0;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .attachment-control-button {
        margin: 0;
    }
}

@media screen and (max-width: 680px) {
    .attachment-text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .attachment-text {
        font-size: 1.4rem;
    }
}

@media screen and (max-widtH: 380px) {
    .attachment-text {
        font-size: 1.3rem;
    }
}
.family-member-add-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    padding: 24px 0 24px 0; 
    border-style: none none solid none;
}

.family-member-add-container button {
    height: 80%;
    width: 80%;
    align-self: center;
}

.family-member-add-container div {
    flex-basis: 24%;
}

.family-member-remove-button-container {
    display: flex;
    justify-content: center;
}

.family-member-remove-button-container button {
    align-self: center;
}

@media screen and (max-width: 1200px) {
    .family-member-add-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .family-member-add-container button {
        height: 100%;

    }
}

@media screen and (max-width: 580px) {
    .family-member-add-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
.find {
    background: #1e272e;
    overflow: auto;
}

/* Find Form */

.find-form {
    padding: 48px 2% 48px 2%;
    border-style: none none solid none;
    color: #fff;
}

.find-form h2 {
    font-size: 3.6rem;
    margin: 0;
}

.find-form button {
    width: 60%;
    margin-top: 24px;
}

.find-form p {
    font-size: 1.6rem;
}

/* Redirect Prompt */

.redirect-prompt {
    padding: 48px 5% 48px 5%;
    color: #fff;
}

.prompt-button {
    flex-basis: 45%;
}

/* Text Area */


.find-area-inputs {
    margin: 1% 15% 1% 15%;
}


/* Error */

.error-message {
    color: rgb(238, 80, 80);
    font-size: 1.6rem;
}

/* Review Container */
.find-review-container {
    margin: 100px 0 0 0;
}

.find-review-container h2 {
    font-size: 2.6rem;
}


.find-review-container h3 {
    font-size: 1.4rem;
}

/* Status */

.find-review-container .application {
    font-size: 3rem;
    margin-top: 0;
}

.find-review-container .denied-button {
    font-size: 3rem;
    background: rgb(238, 80, 80);
}

.find-review-container .pending-button {
    font-size: 3rem;
    background: #fdcb6e;
    color: #222;
}

.find-review-container .approved-button {
    font-size: 3rem;
    background: #55efc4;
}

.application-status-display {
    margin: 24px 0 24px 0;
}

@media screen and (max-width: 736px) {
    .prompt-button {
        width: 100%;
    }

    .find-form p {
        font-size: 1.4rem;
    }

    .find-form button {
        width: 80%;
    }
}

@media screen and (max-width: 480px) {
    .find-review-container {
        margin: 80px 0 0 0;
    }

    .find-form p {
        font-size: 1.2rem;
    }

    .find-form button {
        width: 100%;
    }

}


.donate {
    background: #1e272e; 
    overflow: auto;
    border: #fff;
    color: #fff;
}

.donate-header {
    margin: 148px 5% 48px 5%;
    background: #2d3436;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, #fff 0px 0px 0px 3px;
    border-radius: 50px;
    color: #fff;
}

.donate-header h1 {
    padding: 1%;
    font-size: 3rem;
    margin: 0;
}

.donation-flexbox { 
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    padding-left: 0;
    border-style: none none solid none;
}

.donation-flexbox div {
    flex-basis: 40%;
}

.donation-image {
    max-width: 100%;
    max-height: 100%;
}

.donation-button {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background: none;
    border: solid;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    color: black;
    background: #fdcb6e;
    font-family: 'Poppins', sans-serif;
    transition: all 300ms ease-in-out;
}


@media screen and (max-width: 980px) {
    .donation-flexbox {
        flex-direction: column;
        padding: 24px 1.5% 0 1.5%;
    }
    
    .donation-image {
        max-width: 500px;
        margin-top: 48px;
    }
}

@media screen and (max-width: 510px) {
    .donation-image {
        max-width: 450px;
    }
}

@media screen and (max-width: 460px) {
    .donation-image {
        max-width: 400px;
    }
}

@media screen and (max-width: 410px) {
    .donation-image {
        max-width: 350px;
    }
}

@media screen and (max-width: 360px) {
    .donation-image {
        max-width: 300px;
    }
}

@media screen and (max-width: 310px) {
    .donation-image {
        max-width: 200px;
    }
}
.contact {
    background: #1e272e; 
    overflow: auto;
    border: #fff;
    color: #fff;
}

.contact-body {
    padding: 48px 8.5% 48px 8.5%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
    color: #fff;
}

.contact-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    justify-content: space-evenly;
}

.contact-item {
    margin: 0;
    padding: 0;
}
.board-member-form {
    padding: 24px 8.5% 24px 8.5%;
}

.board-member-form h1 {
    font-size: 3.4rem;
}

.board-member-form p {
    text-align: center;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.board-member-form button {
    width: 80%;
}

.board-member-form-question {
    padding: 12px 0 12px 0;
}

.board-member-submit-button-label {
    padding: 12px 0 12px 0;
}

@media screen and (max-width: 1500px) {
    .board-member-form {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .board-member-form {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .board-member-form {
        padding: 24px 2.5% 24px 2.5%;
    }
}
.ambassador-form {
    padding: 24px 8.5% 24px 8.5%;
}

.ambassador-form h1 {
    font-size: 3.4rem;
}

.ambassador-form p {
    text-align: center;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.ambassador-form button {
    width: 80%;
}

.ambassador-form-question {
    padding: 12px 0 12px 0;
}

.ambassador-submit-button-label {
    padding: 12px 0 12px 0;
}

@media screen and (max-width: 1500px) {
    .ambassador-form {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .ambassador-form {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .ambassador-form {
        padding: 24px 2.5% 24px 2.5%;
    }
}
.affiliate-form {
    padding: 24px 8.5% 24px 8.5%;
}

.affiliate-form h1 {
    font-size: 3.4rem;
}

.affiliate-form p {
    text-align: center;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.affiliate-form button {
    width: 80%;
}

.affiliate-form-question {
    padding: 12px 0 12px 0;
}

.affiliate-submit-button-label {
    padding: 12px 0 12px 0;
}

.affiliate-form-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 0 24px 0;
}

.affiliate-form-items div {
    flex-basis: 32%;
}

@media screen and (max-width: 1500px) {
    .affiliate-form {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .affiliate-form {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .affiliate-form {
        padding: 24px 2.5% 24px 2.5%;
    }
}
.join {
    background: #1e272e;
    overflow: auto;
}

/* Prompt */

.join-login-prompt {
    margin: 24px 25% 24px 25%;
    padding: 1%;
    background: #2d3436;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, #fff 0px 0px 0px 3px;
    border-radius: 50px;
    color: #fff;
}

.approved-button:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.denied-button:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

.join-section {
    height: 25%;
    padding: 48px 8.5% 0 8.5%;
    color: #fff;
    border-style: none;
}

.join-section h1 {
    margin: 12px 0 12px 0;
    font-size: 3.4rem;
    align-self: baseline;
    text-align: center;
}

.join-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0 12px 0;
}

.join-nav button {
    flex-basis: 32%;
    width: 100%;
}


/* Form */

.join-form {
    padding: 1%;
    color: #fff;
}

.join-form h1 {
    font-size: 3.4rem;
}

.join-form p { 
    text-align: center;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
}

.join-form button {
    width: 80%;
}

.form-items {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    padding: 0;

}

.middle-row {
    margin: 24px 0 24px 0;
    display: flex;
    justify-content: space-evenly;
}

.textarea-section {
    margin: 24px 15% 24px 15%;
}


@media screen and (max-width: 1500px) {
    .join-section {
        padding: 48px 6.5% 24px 6.5%
    }
}

@media screen and (max-width: 1260px) {
    /* Prompt */

    .join .prompt {
        border-style: none none solid none;
        margin: 24px 0 24px 0;
        padding: 15px 10px 15px 10px;
        text-align: center;
        color: #fff;
    }

    .prompt h1 {
        font-size: 2.8rem;
    }

    .join .prompt button {
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    .join-section {
        padding: 24px 5.5% 24px 5.5%
    }
}

@media screen and (max-width: 1100px) {
    .join-section {
        padding: 24px 2.5% 24px 2.5%
    }
}
.report {
    background: #1e272e;
    overflow: auto;
    border: #fff;
    color: #fff;
}

.report-form {
    padding: 48px 8.5% 48px 8.5%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.report-form h1 {
    font-size: 3rem;
    margin: 0;
}

.report-form p {
    font-size: 1.2rem;
}

.report-form button {
    width: 40%;
}

.report-form-content {
    display: flex;
    justify-content: space-evenly;
}

.report-form-item {
    margin: 24px 20% 0 20%;
}

.report-form-submit-button {
    width: 80%;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 8px;
    border: none;
    text-decoration: none;
    font-size: 2.4rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.no:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

.yes:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.incomplete {
    color: #d63031;
}

.completed {
    color: #00b894;
}

@media screen and (max-width: 1260px) {
    .report-form h1 {
        font-size: 3.4rem;
    }

    .report-form-item {
        margin: 24px 10% 0 10%;
    }

    .report-form button {
        width: 90%;
    }
}

@media screen and (max-width: 780px) {
    .report-form {
        padding: 48px 2% 48px 2%;
    }

    .report-form h1 {
        font-size: 3.2rem;
    }

    .report-form-item {
        margin: 24px 2% 0 2%;
    }

    .report-form button {
        width: 100%;
    }

}

@media screen and (max-width: 560px) {
    .report-form-item label {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 480px) {
    .report-form h1 {
        font-size: 3rem;
    }

    .report-form-item label {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 420px) {
    .report-form h1 {
        font-size: 2.8rem;
    }

    .report-form-item label {
        font-size: 1rem;
    }
}
.login-container {
    padding-bottom: 30px;
    border-style: none none none solid;
    color: #fff;
}

.login-box form {
    margin: 0 25% 0 25%;
}


.login-flexbox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}

.login-buttons {
    margin-top: 24px;
    display: grid;
    flex-direction: column;
    grid-gap: 24px;
}

.login-button {
    width: 60%;
}

.login-toggle-button {
    width: 60%;
}

.login-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-container h1 {
    margin-bottom: 20px;
    font-size: 3.2em;
    text-align: center;
}


.login-container label {
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 20px;
    color: #fff;
    transition: .5s;
    align-self: center;
}

.login-container textarea {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    resize: none;
    text-align: center;
}

.login-container button {
    align-self: center;
}

.login-form {
    vertical-align: baseline;
}

.buttons-container {
    list-style: none;
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    text-align: center;
    clear: left;
    padding: 0;

}

.buttons-container li {
    margin-top: 12px;
}

/* Verification */

.verification-form {
    height: 25%;
    margin: 148px 5% 30px 5%;
    padding-bottom: 30px;
    background: #2d3436;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, #fff 0px 0px 0px 3px;
    border-radius: 50px;
    color: #fff;
}

/* Laptop / Tablet Screens */

@media screen and (max-width: 1700px) {
    .login-button {
        width: 80%;
    }
}

/* Small Screens */

@media screen and (max-width: 1286px) {
    .login-button {
        width: 100%;
    }

    .login-image {
        flex-basis: 30%;
    }

    .login-image img {
        width: 100%;
    }

    .login-box form {
        margin: 0 15% 0 15%;
    }
}

/* Mobile */

@media screen and (max-width: 925px) {

    .login-flexbox {
        display: inline;
    }

    .login-container {
        border-style: none;
        height: 100%;

    }

    .login-container h1 {
        font-size: 3rem;
    }

    .login-box form {
        margin: 0 5% 0 5%;
    }

}

@media screen and (max-width: 420px) {
    .login-container h1 {
        font-size: 2.8rem;
    }
}
.login {
    background: #1e272e; 
    overflow: auto;
}

.login-image {
    flex-basis: 40%;
}

.login-container {
    flex-basis: 50%;
}

/* Login Box */

.login-box h2 {
    font-size: 2.6rem;
}

.error-text {
    color: #d63031;
    margin-top: 10px;
    margin-bottom: 10px;
}

.denied-button:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

.pending-button:hover {
    background: #fdcb6e;
    transition: all 300ms ease-in-out;
}

.approved-button:hover {
    background: #55efc4;
    transition: all 300ms ease-in-out;
}

.hugs-logo-login {
    max-width: 450px;
}
.event-signup {
    border-style: solid;
    padding: 1%;
    margin: 24px 25% 24px 25%
}

.instruction-text {
    font-size: 2rem;
}

@media screen and (max-width: 1200px) {
    .event-signup {
        border-style: none none solid none;
        margin: 24px 0 24px 0;
    }

    .event-signup button {
        margin-bottom: 24px;
    }

}

@media screen and (max-width: 810px) {
    .instruction-text {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 710px) {
    .instruction-text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 610px) {
    .instruction-text {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 480px) {
    .instruction-text {
        font-size: 1.2rem;
    }

    .event-signup .p-inputtext {
        width: 90%;
    }
}
.newsletter-signup-items .event-question-container {
    border-style: none;
}

.newsletter-signup-items .success {
    padding: 12px 0 12px 0;
}
/* Section Header */

.events-section-header {
    padding: 24px 0 24px 0;
    border-style: none none solid none;
}

.events-section-header h1 {
    font-size: 3.6rem;
    margin: 0;
}

/* Events Display */

.events-display {
    padding: 48px 0 48px 0;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
    font-family: 'Poppins', sans-serif;
}

.events-display h1 {
    font-size: 3.2rem;
    margin: 0;
}

.events-display-row {
    padding: 24px 8.5% 24px 8.5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
}

.event-info-header {
    font-size: 3rem;
    margin: 24px 0 24px 0;
}

.event-location-header {
    font-size: 2.4rem;
    margin: 0 0 24px 0;
    font-family: 'Poppins', sans-serif;
}

.event-date-header {
    font-size: 2.4rem;
    margin: 24px 0 24px 0;
    font-family: 'Poppins', sans-serif;
}

.events-display-row h1 {
    font-size: 3rem;
    text-align: center;
    align-self: flex-start;
}

.events-display-row h3 {
    font-size: 1.4rem;
    margin: 0;
}

.events-display-row button {
    text-align: center;
    width: 100%;
}

.event-description {
    font-size: 1.2rem;
}

.events-display-info {
    flex-basis: 35%;
    text-align: left;
}

.events-display-image {
    flex-basis: 40%;
    text-align: center;
}

.events-display-image img {
    max-width: 500px;
    border-radius: 8px;
}

.event-signup button {
    width: 80%;
}

/* Carousel */

.main-carousel {
    margin: 24px 0 24px 0;
}

.main-carousel img {
    max-height: 800px;
    border-style: solid;
    align-self: center;
}

.thumbs img {
    max-width: 150px;
    border-style: solid;
}

/* Swiper */
.swiper-main img {
    max-width: 600px;
    vertical-align: middle;
}

@media screen and (max-width: 1500px) {
    .events-display-row {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1300px) {
    .swiper-main img {
        max-width: 1000px;
    }
}

@media screen and (max-width: 1200px) {
    .events-display-row {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1260px) {
    .events-section-header h1 {
        font-size: 3.2rem;
    }

    .events-display h1 {
        font-size: 3rem;
    }

    .events-display-row h1 {
        font-size: 2.8rem;
    }
}


/* Mobile Handling */

@media screen and (max-width: 1200px) {
    .events-display {
        padding: 24px 2.5% 24px 2.5%;
    }

    .events-display-row {
        flex-direction: column-reverse;
    }

    .events-display-image img {
        max-width: 500px;
    }

    .events-display-info {
        margin: 24px 1% 24px 1%;
        width: 90%;
    }

    .event-display-info {
        padding-left: 24px;
    }

    .event-description {
        padding: 0 24px 0 24px;
    }
}

@media screen and (max-width: 810px) {
    .swiper-main img {
        max-width: 700px;
    }
}

@media screen and (max-width: 780px) {
    .events-section-header h1 {
        font-size: 2.8rem;
    }

    .events-display h1 {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 710px) {
    .swiper-main img {
        max-width: 600px;
    }
}

@media screen and (max-width: 610px) {
    .swiper-main img {
        max-width: 500px;
    }
}

@media screen and (max-width: 580px) {

}

@media screen and (max-width: 510px) {
    .events-display-image img {
        max-width: 450px;
    }

    .swiper-main img {
        max-width: 400px;
    }

    .event-description {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 480px) {
    .events-display-image img {
        max-width: 400px;
    }

    .events-section-header h1 {
        font-size: 2.4rem;
    }

    .events-display h1 {
        font-size: 2.6rem;
    }

    .events-display-row h1 {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 420px) {
    .events-display-image img {
        max-width: 350px;
    }

    .events-display h1 {
        font-size: 2.4rem;
    }

    .events-display-row h1 {
        font-size: 2.4rem;
    }

    .event-description {
        font-size: 1.1rem;
    }

    .events-display-row h3 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 410px) {
    .swiper-main img {
        max-width: 300px;
    }
}

@media screen and (max-width: 380px) {
    .event-description {
        font-size: 1rem;
    }
}

@media screen and (max-width: 370px) {
    .events-display-image img {
        max-width: 300px;
    }
}

@media screen and (max-width: 320px) {
    .events-display-image img {
        max-width: 250px;
    }
}

@media screen and (max-width: 310px) {
    .swiper-main img {
        max-width: 200px;
    }
}
.events {
    background: #1e272e; 
    overflow: auto;
    border: #fff;
    color: #fff;
}

.top-header-margin h1 {
    margin-top: 100px;
}
.event-signin {
    padding: 24px 8.5% 24px 8.5%;
}

@media screen and (max-width: 1500px) {
    .event-signin {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .event-signin {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .event-signin {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 780px) {
    .event-signin-container h1 {
        font-size: 2.8rem;
    }
}

@media screen and (max-width: 640px) {
    .event-signin-container h1 {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 550px) {
    .event-signin-container h1 {
        font-size: 2rem;
    }
}
.event-signin-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.event-signin-nav button {
    width: 80%;
    margin: 12px 0 12px 0;
    flex-basis: 49%;
}

.event-signin-form-section {
    margin: 12px 0 12px 0;
}

.event-signin-form-section label {
    margin: 12px 0 12px 0;
}

.event-signin-form-section .apply-buttons-container {
    margin: 12px 0 12px 0;
}

.event-signin-form-section-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.event-signin-form-section .submit-button {
    width: 80%;
}

.event-signin-attending-list table {
    width: 100%;
    margin-top: 24px;
}

.event-signin-attending-list th {
    font-size: 2rem;
}

.event-signin-attending-list tr {
    font-size: 1.6rem;
    border-spacing: 12px;
}

@media screen and (max-width: 900px) {
    .event-signin-form-section-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .event-signin-nav {
        flex-direction: column;
        align-items: center;
    }

    .even-signin-nav button {
        width: 100%;
    }
}

@media screen and (max-width: 580px) {
    .event-signin-form-section-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}
.employee-navbar-items {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    list-style: none;
    text-align: center;
}

.employee-navbar-items div {
    flex-basis: 24.5%;
}

.employee-navbar-items button {
    width: 100%;
}

.employee-dashboard-nav-application-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.employee-dashboard-nav-application-dropdown-child {
    margin: 12px 0 12px 0;
}

@media screen and (max-width: 1200px) {
    .employee-navbar-items {
        padding: 12px 3% 12px 3%;
    }
}

@media screen and (max-width: 1000px) {
    .employee-navbar-items {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 24px;
    }
}

@media screen and (max-width: 850px) {
    .employee-navbar-items button {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 500px) {
    .employee-navbar-items button {
        font-size: 1.1rem;
    }
}
/* Notes */

.notes {
    border-style: none none solid none;
}

.notes h1 {
    font-size: 3.6rem;
}

.note-header {
    margin: 0 0 24px 0;
}

.notes-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;
    padding: 1% 5% 1% 5%;
}

.notes-list li {
    border-style: solid;
    padding: 24px
}

.notes-list h1 {
    font-size: 3rem;
    text-align: left;
}

.notes-list h2 {
    font-size: 2rem;
    text-align: left;
}

.notes-list p {
    font-size: 1.2rem;
    text-align: left;
}

.notes-control-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
}

.notes-control-list li {
    flex-basis: 49%;
}

.notes-control-list li button {
    width: 100%;
}

.note-form-control {
    display: flex;
    justify-content: space-between;
}

.note-form-control button {
    flex-basis: 40%;
}

.note-form button {
    width: 80%;
}

.only-add {
    width: 80%;
    margin: 24px 0 24px 0;
}

/* Deletion */

.note-for-deletion {
    display: flex;
    justify-content: space-between;
}


.delete-note-button {
    margin: 24px;
    height: 50%;
}

@media screen and (max-width: 1500px) {
    .notes-control-list {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .notes-list h1 {
        font-size: 2rem;
        text-align: left;
        margin: 24px;
    }
    
    .notes-list h2 {
        font-size: 1.6rem;
        text-align: left;
        margin-left: 24px;
    }
    
    .notes-list p {
        font-size: 1.2rem;
    }

    .notes-control-list {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .notes-control-list {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 1000px) {
    .notes-list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
.application-preview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 24px 0 24px 0;
    padding: 0;
    width: 100%;
}

.mobile-application-preview {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 24px 0 24px 0;
    padding: 0;
    width: 100%;
}
.affiliate-preview-container {
    margin: 24px 0 24px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.affiliate-edit-nav {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
}

.affiliate-edit-nav button {
    width: 100%;
    flex-basis: 33%;
}

.affiliate-edit {
    padding: 24px 8.5% 24px 8.5%;
}

.employee-affiliate-review-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 1500px) {
    .affiliate-edit-nav {
        padding: 24px 6.5% 24px 6.5%;
    }
    .affiliate-edit {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .affiliate-edit-nav {
        padding: 24px 5.5% 24px 5.5%;
    }
    .affiliate-edit {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .affiliate-edit-nav {
        padding: 24px 2.5% 24px 2.5%;
    }
    .affiliate-edit {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 780px) {
    .employee-application-review-items {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 580px) {
    .employee-application-review-items {
        grid-template-columns: repeat(1, 1fr);
    }
}
.affiliate-control-top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
}

.affiliate-control-top-row button {
    width: 100%;
    flex-basis: 33%;
}

.affiliate-control-search-button-container button {
    width: 80%;
}
.display-container {
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}

.section-container p {
    font-size: 1.4rem;
}

.event-add {
    padding: 24px 8.5% 24px 8.5%;
}

.event-add .active-section {
    padding: 24px 0 24px 0;
}

.event-add-row {
    display: flex;
    justify-content: space-between;
}

.event-add-row label {
    flex-basis: 49%;
    margin: 1%;
}

.event-add-row div {
    flex-basis: 33%;
}

.add-submit-button {
    width: 80%;
    margin-top: 48px;
}

.event-instructions .p-inputtext {
    width: 100%;
}

.event-question-text-container {
    border-style: none none solid none;
}

.event-question-container {
    border-style: none none solid none;
    padding: 12px 0 12px 0;
}

.question-row {
    display: flex;
    align-content: center;
}

.question-content-container {
    flex-basis: 60%;
    margin: 12px;
    align-self: center;
}

.question-type-container {
    flex-basis: 30%;
    margin: 12px;
    align-self: center;
}

.question-remove-container {
    margin: 12px;
    display: flex;
    align-self: center;
}

.question-remove-container button {
    height: 40%;
    align-self: center;
}

.question-label {
    display: block;
}

.question-header-label {
    text-align: left;
}

.add-question-button-container {
    padding: 24px 0 24px 0;
}

@media screen and (max-width: 1500px) {
    .event-add {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .event-add {
        padding: 24px 5.5% 24px 5.5%;
    }

    .question-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .question-remove-container button {
        width: 100%;
    }

}

@media screen and (max-width: 1100px) {
    .event-add {
        padding: 24px 2.5% 24px 2.5%;
    }

}

@media screen and (max-width: 850px) {
    .question-row {
        grid-template-columns: repeat(1, 1fr);
    }

    .event-add-row {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}
.event-archive-confirm {
    margin: 24px 0 24px 0;
}

.event-archive-confirm button {
    width: 40%;
}

.event-archive-input .p-inputtext {
    width: 80%;
}

.event-archive-submit {
    margin: 24px 0 24px 0;
}

.event-archive-submit button {
    width: 80%;
}
.image-nav {
    display: flex;
    justify-content: space-between;
    border-style: none none solid none;
    padding: 24px 8.5% 24px 8.5%;
}

.image-nav button {
    width: 100%;
    flex-basis: 49%;
}

.event-image-change img {
    max-width: 500px;
}

.main-image-change {
    padding: 24px 8.5% 24px 8.5%;
}

.image-add-container {
    padding: 24px 0 24px 0;
}

@media screen and (max-width: 1500px) {
    .image-nav {
        padding: 24px 6.5% 24px 6.5%;
    }

    .main-image-change {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .image-nav {
        padding: 24px 5.5% 24px 5.5%;
    }

    .main-image-change {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .image-nav {
        padding: 24px 2.5% 24px 2.5%;
    }

    .main-image-change {
        padding: 24px 2.5% 24px 2.5%;
    }
}

.event-edit-signin-container {
    margin: 24px 0 24px 0;
    border-style: solid none none none;
    padding-top: 24px;
}

.event-edit-signin-section-header {
    margin: 24px 0 24px 0;
}

.event-edit-signin-information-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 24px 0 24px 0;
}

.event-edit-signin-opt-ins-list {
    margin: 24px 0 24px 0;
}

@media screen and (max-width: 920px) {
    .event-edit-signin-information-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 680px) {
    .event-edit-signin-information-list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
.employee-permissions-display {
    align-self: center;
}

.permissions-list {
    list-style: none;
    padding: 0;
    text-align: center;
}

.permission-toggle-display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0 12px 0;
}

.permission-name {
    font-size: 2rem;
    margin: 12px 0 12px 0;
    align-self: center;
    font-family: 'Poppins', sans-serif;
}

.permission-buttons {
    flex-basis: 45%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.permission-buttons button {
    align-self: center;
    width: 45%;
}

.update-permissions-button {
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .permission-name {
        font-size: 1.8rem;
    }
}

.sponsor-header {
    border-style: none none solid none;
}

.settings-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
}

.settings-control .header {
    font-size: 2.6rem;
    padding: 24px 0 24px 0;
}

.settings-items button {
    width: 32%;
}

@media screen and (max-width: 1500px) {
    .settings-items {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .settings-items {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .settings-items {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 850px) {
    .settings-items {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
        margin: 24px 0 24px 0;
    }

    .settings-items button {
        width: 100%;
        align-self: center;
    }
}
/* Navbar */

.employee-navbar {
    padding: 24px 5% 24px 5%;
    display: flex;
    justify-content: space-evenly;
    border-style: none none solid none;
}

.employee-navbar-button {
    flex-basis: 18%;
}

.employee-navbar-button:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

/* Display Container */

.display-container {
    padding: 0 8.5% 24px 8.5%;
}

.display-container .header {
    font-size: 2.6rem;
    padding: 24px 0 24px 0;
}

.view-full-button {
    width: 80%;
}

/* Application Control */

.application-container h2 {
    font-size: 1.8rem;
}

.application-container p {
    font-size: 1.2rem;
}

.assistance-description {
    padding: 0 8.5% 0 8.5%;
}

.description-container {
    margin: 1% 10% 1% 10%;
    padding: 0;
}

/* Search */

.application-search {
    padding: 1%;
}

.search-input {
    margin: 24px 20% 24px 20%;
}

.note-input {
    margin: 24px 20% 24px 20%;
}

.back-button {
    display: flex;
    align-content: flex-start;
    margin: 24px 0 24px 8.5%;
}

.back-button button {
    flex-basis: 40%;
}

.search-item {
    margin: 12px 10% 12px 10%;
    border-style: solid;
}

/* Control Buttons */

.control-items {
    display: flex;
    justify-content: space-between;
    padding: 24px 0 24px 0;
    list-style: none;
    align-items: center;
    text-align: center;
}

.application-edit-header {
    margin: 24px 0 24px 0;
}

.application-edit-nav {
    padding: 24px 8.5% 24px 8.5%;
}

.application-edit-nav-row-1 {
    display: flex;
    justify-content: space-between;
    list-style: none;
    align-items: center;
    text-align: center;
}

.application-edit-nav-row-1 button {
    flex-basis: 32.5%;
    width: 100%;
}

.application-edit-nav-row-2 {
    display: flex;
    justify-content: space-between;
    list-style: none;
    align-items: center;
    text-align: center;
    padding-top: 12px;
}

.application-edit-nav-row-2 button {
    flex-basis: 49.5%;
    width: 100%;
}

.application-control .full-button {
    width: 80%;
    margin: 0 0 1% 0;
}

.application-control-button {
    flex-basis: 24.5%;
}

.application-control-two-button-section {
    display: flex;
    justify-content: space-between;
}

.application-control-half-section-button {
    width: 100%;
    flex-basis: 49.5%;
}

.event-control .control-items button {
    flex-basis: 24.5%;
}

.add-event-button-container {
    width: 100%;
}

.add-event-button-container button {
    width: 80%;
}

.event-edit-options-select-nav {
    display: flex;
    justify-content: space-between;
}

.event-edit-options-select-nav button {
    width: 100%;
    flex-basis: 49%;
}

.attending-display {
    margin: 24px 0 24px 0;
}

/* Legacy Applications */

.legacy-container {
    padding: 24px 0 48px 0;
}

.legacy-control {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    margin: 10px;
}

.legacy-application-info-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display: grid;
}

.legacy-add {
    margin-top: 24px;
}

.legacy-row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    margin: 10px 40px 10px 40px;
}

.legacy-submit-button {
    width: 80%;
    margin: 24px 0 24px 0;
}


/* Application Edit */

.application-edit {
    overflow: visible;
}

.application-header {
    padding: 24px 0 24px 0;
    border-style: none none solid none;
}

.container-header {
    padding: 0 0 24px 0;
    font-size: 3.2rem;
    border-style: none none solid none;
}

.applications-list {
    display: grid;
    list-style: none;
    padding: 0;
}

.employee-review-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    border-style: none none solid none;
}

.employee-review-container .employee-application-review-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.employee-review-container .review-section {
    margin: 0;
    border-style: none none solid none;
    padding: 24px 8.5% 24px 8.5%;
    height: auto;
}

.application-edit-display {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.application-edit-section {
    padding: 24px 10% 24px 10%;
}

.application-edit-section h1 {
    font-size: 3.6rem;
}

.application-edit-section h2 {
    font-size: 2.6rem;
}

.application-edit-section .attachment-text {
    font-size: 1.6rem;
}

.application-edit-section p {
    font-size: 1.2rem;
}

.application-edit-section button {
    width: 80%;
}

.report-info-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 0;
}

.application-edit-item {
    flex-basis: 40%;
}

.details-text {
    margin: 0 10px 10px 10px;
}

.edit-button-row {
    padding: 1% 5% 1% 5%;
    display: flex;
    justify-content: space-evenly;
    border-style: none none solid none;
}

.edit-button-item {
    flex-basis: 20%;
}

.employee-review-container h2 {
    font-size: 2.6rem;
}

.employee-review-container .attachment-text {
    font-size: 1.6rem;
}


.employee-review-container h3 {
    font-size: 2rem;
}

.employee-review-container {
    font-size: 1.6rem;
}

.amount-box {
    margin: 24px 0 24px 0;
}

.status-list {
    display: flex;
    justify-content: space-evenly;
    margin: 24px 0 24px 0;
}

.status-list div {
    flex-basis: 40%;
}

.status-list div button {
    width: 100%;
}

.status-buttons-container {
    margin: 24px 0 24px 0;
}

/* Verify */

.pending-verification {
    padding: 1%;
    border-style: none none solid none;
}

.response-template {
    text-align: left;
    margin: 24px 25% 24px 25%;
}

.response-password-input-container {
    margin: 12px 25% 12px 25%
}

.response-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 12px 0 12px 0;
}

.response-buttons div {
    flex-basis: 40%;
}

.response-buttons button {
    width: 100%;
}

/* Attachments */

.attachments-container {
    list-style: none;
    padding: 1%;
}

.attachment-submit-button {
    width: 80%;
    margin-top: 48px;
}

/* User Info */

.user-info h1 {
    font-size: 3.6rem;
}

.user-info h2 {
    font-size: 2.6rem;
}

/* Events */

.event-edit-row {
    display: flex;
    justify-content: space-between;
}

.event-edit-row label {
    flex-basis: 49%;
    margin: 1%;
}

.event-edit-row div {
    flex-basis: 33%;
}

.event-edit-padding {
    padding: 24px 0 24px 0;
}

.event-control-buttons {
    display: flex;
    justify-content: space-evenly;
}

.event-control-buttons div {
    flex-basis: 19%;
}

.event-control-buttons button {
    width: 100%;
}

.event-control-items {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
    list-style: none;
    align-items: center;
    text-align: center;
    border-style: none none solid none;
}

.event-control-items div {
    flex-basis: 24%;
}

.event-control-items button {
    width: 100%;
}

.event-buttons-container {
    display: flex;
    justify-content: space-between;
}

.event-buttons-container button {
    flex-basis: 49%;
}

.event-edit {
    padding: 24px 8.5% 24px 8.5%;
}

.event-time {
    margin: 0 30% 0 30%;
}

/* Edit */

.application-info-list .p-inputtext {
    width: 80%;
}

.yes-button {
    padding: 0.5rem 1rem;
    width: 100%;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.yes-button-selected {
    padding: 0.5rem 1rem;
    width: 100%;
    white-space: nowrap;
    background-color: #00b894;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.no-button {
    padding: 0.5rem 1rem;
    width: 100%;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

.no-button-selected {
    padding: 0.5rem 1rem;
    width: 100%;
    white-space: nowrap;
    background-color: #d63031;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    transition: all 300ms ease-in-out;
}

/* Report */

.report-control {
    padding: 24px 0 24px 0;
    display: flex;
    justify-content: space-evenly;
}

.report-control button {
    width: 100%;
    flex-basis: 32%;
}

/* User Control */

.user-control .control-items button {
    flex-basis: 24%;
}

.user-control-buttons {
    display: flex;
    justify-content: space-between;
    margin: 24px 10% 24px 10%;
}

.permission-control-buttons {}

.event-control-items .change-permissions-button {
    width: 40%;
}

.user-control-item {
    flex-basis: 45%;
}

.user-control-item button {
    width: 100%;
}

.response-password-input-container h2 {
    margin: 24px 0 24px 0;
}

.response-password-input-container button {
    margin: 24px 0 24px 0;
}

/* User Control Notes */

.application-container .note-form form {
    margin: 24px 0 24px 0;
}

.application-container .notes-control-list {
    margin: 24px 0 24px 0;
}

.user-info-button {
    margin: 12px 5% 12px 5%;
}

.two-button-section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
}

.one-button-section {
    padding: 12px 0 12px 0;
}

.one-button-section button {
    width: 100%;
}

/* Stats */

.stats-nav {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    margin: 10px 0 10px 0;
    padding: 1% 5% 1% 5%;

}

.stats-remove-section {
    margin: 24px 0 24px 0;
}

.stats-remove-section .p-inputtext {
    width: 60%;
}

.all-stats-button {
    width: 60%;
}

.year-nav {
    margin: 10px 0 10px 0;
    padding-bottom: 10px;
    border-style: none none solid none;
}

.year-nav-month-buttons {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-gap: 10px;
    padding: 1% 5% 1% 5%;
}

.year-all-button {
    width: 80%;
}

.month-nav {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-gap: 10px;
    padding: 1% 5% 1% 5%;
    margin: 10px 0 10px 0;
    border-style: none none solid none;
}

.month-data {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px;
    margin: 10px 0 10px 0;
    border-style: solid none solid none;
}

.day-data {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px;
    margin: 10px 0 10px 0;
}

.stats-content-button {
    background: #273c75;
    border-color: white;
}

.stats-content-button:hover {
    background: #55efc4;
    color: black;
    border-color: white;
    transition: all 300ms ease-in-out;
}

@media screen and (max-width: 1680px) {
    .legacy-application-info-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1600px) {

    .legacy-application-info-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

}

@media screen and (max-width: 1500px) {
    .display-container {
        padding: 0 6.5% 24px 6.5%;
    }

    .mobile-application-edit-control-container {
        padding: 24px 6.5% 24px 6.5%;
    }

    .employee-review-container .review-section {
        padding: 24px 6.5% 24px 6.5%;
    }

    .event-control-items {
        padding: 24px 6.5% 24px 6.5%;
    }

    .back-button {
        margin: 24px 0 24px 6.5%;
    }

    .event-control .control-items button {
        flex-basis: 17%;
    }
}

@media screen and (max-width: 1450px) {
    /* Event Control */

    .mobile-event-control-row-1 {
        display: flex;
        justify-content: space-between;
        padding: 1%;
        list-style: none;
        align-items: center;
        text-align: center;
        border-style: none;
    }

    .mobile-event-control-row-1 button {
        flex-basis: 32%;
    }

    .mobile-event-control-row-2 {
        display: flex;
        justify-content: space-between;
        padding: 1%;
        list-style: none;
        align-items: center;
        text-align: center;
        border-style: none;
    }

    .mobile-event-control-row-2 button {
        flex-basis: 49%;
    }

    .applications-list {
        padding: 0;
    }

    .application-container button {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1300px) {
    .display-container .header {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 1200px) {
    .control-items {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
        padding: 12px;
        border-style: none;
    }

    .back-button {
        margin: 24px 0 24px 5.5%;
    }

    .display-container {
        padding: 0 5.5% 24px 5.5%;
    }

    .display-container .header {
        font-size: 2rem;
    }

    .view-full-application-button button {
        font-size: 1.6rem;
        width: 90%;
    }

    .application-container h2 {
        font-size: 1.6rem;
    }

    .application-header h1 {
        font-size: 2.6rem;
    }

    /* Application Edit Control */

    .mobile-control {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px;
        padding: 0;
    }

    .employee-review-container .review-section {
        padding: 24px 5.5% 24px 5.5%;
    }


    .mobile-control button {
        flex-basis: 32%;
    }

    .container-header {
        margin: 0;
    }

    .mobile-application-edit-control-container {
        padding: 24px 5.5% 24px 5.5%;
    }

    .mobile-application-edit-control-row-1 {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
    }

    .mobile-application-edit-control-row-1 button {
        flex-basis: 32.5%;
    }

    .mobile-application-edit-control-row-2 {
        display: flex;
        justify-content: space-between;
    }

    .mobile-application-edit-control-row-2 button {
        flex-basis: 49.5%;
    }

    .application-edit-section {
        padding: 24px;
    }

    /* Review Container */

    .employee-review-container h3 {
        font-size: 1.6rem;
    }

    .attachment-control-button {
        flex-basis: 30%;
    }

    /* Events */

    .search-item {
        margin: 12px 10% 12px 10%;
    }

    .event-control-items {
        padding: 24px 5.5% 24px 5.5%;
    }

    /* Stats */
    .all-stats-button {
        width: 80%;
    }

    .year-nav {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .month-data {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .employee-review-container .attachment-text {
        font-size: 1.2rem;
    }

    .report-info-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1150px) {
    .application-container h2 {
        font-size: 1.5rem;
    }

    .application-header h1 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 1100px) {
    .display-container {
        padding: 0 2.5% 24px 2.5%;
    }

    .back-button {
        margin: 24px 0 24px 2.5%;
    }

    .mobile-application-edit-control-container {
        padding: 24px 2.5% 24px 2.5%;
    }

    .event-control-items {
        padding: 24px 2.5% 24px 2.5%;
    }

    .employee-review-container .review-section {
        padding: 24px 2.5% 24px 2.5%;
    }

}

@media screen and (max-width: 1050px) {
    .event-control-items {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1000px) {
    .application-container h2 {
        font-size: 1.4rem;
    }

    .application-header h1 {
        font-size: 2.4rem;
    }

    .employee-review-container h3 {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 980px) {

    .application-header h1 {
        font-size: 2.8rem;
    }

    .mobile-control {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .report-info-list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (max-width: 860px) {
    .mobile-event-control-row-1 {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }
}

@media screen and (max-width: 780px) {
    .application-header {
        padding: 12px 0 12px 0;
    }

    .application-header h1 {
        font-size: 2.6rem;
    }

    .application-container h2 {
        font-size: 1.3rem;
    }

    .application-header h1 {
        font-size: 2.3rem;
    }

    .employee-review-container h3 {
        font-size: 1.2rem;
    }

    .employee-review-container .employee-application-review-items {
        grid-template-columns: repeat(2, 1fr);
    }


    /* Stats */
    .stats-nav {
        border-style: none none solid none;
    }

    .year-nav {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .month-nav {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .day-data {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .mobile-application-edit-control-row-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        display: grid;
        grid-gap: 12px;
    }

    .mobile-application-edit-control-row-2 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        display: grid;
        grid-gap: 12px;
    }
}

@media screen and (max-width: 680px) {
    .event-control-items {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .mobile-control-row-1 {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .applications-list-padding {
        padding: 0 12px 0 12px;
    }

    .application-container {
        width: 100%;
        padding: 12px 0 12px 0;
    }

    .application-container h2 {
        font-size: 1.2rem;
    }

    .application-container p {
        font-size: 1.1rem;
    }

    .application-header h1 {
        font-size: 2.2rem;
    }

    /* Legacy */
    .legacy-row {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .legacy-control {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .legacy-submit-button {
        width: 95%;
    }

    /* Stats */
    .stats-nav {
        margin: 10px 5px 10px 5px;
    }

    .month-data {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (max-width: 580px) {
    .employee-review-container .employee-application-review-items {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 480px) {


    .application-container button {
        font-size: 1.1rem;
    }

    .applications-list-padding {
        padding: 0;
    }

    /* Event Control */

    .mobile-event-control-row-2 {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .employee-review-container h2 {
        font-size: 2.3rem;
    }

    .employee-review-container h3 {
        font-size: 1.2rem;
    }

    .review-section p {
        font-size: 1rem;
    }

    /* Stats */

    .year-nav {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

}
/* Dashboard */

.dashboard-row-container {
    display: flex;
    flex-direction: row;
    padding: 0 1% 0 1%;
    list-style: none;
    justify-content: space-between;
    border-style: none none solid none;
    align-items: stretch;
    margin: 0;
}

/* flexbox resizing */

.dashboard-item-infobox {
    flex-basis: 25%;

}

.dashboard-item-applications-container {
    flex-basis: 65%;
    border-style: none none none solid;
}

/* UserInfoBox */

.user-info-box {}

.user-info-box h3 {
    margin: 0;
}

.dashboard h1 {
    font-size: 2.6rem;
}

.user-info-list {
    list-style: none;
    justify-content: space-evenly;
    align-items: left;
    text-align: left;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 24px;
}

.user-info-list h3 {
    font-size: 2rem;
}

.user-info-list p {
    font-size: 1.2rem;
}

/* User Applications */

/* Empty */

.user-applications-container-empty {
    color: #fff;
}

.user-applications-container-empty h1 {
    font-size: 3rem;
}

.no-applications-message {
    margin-top: 150px;
}

/* Filled */

.user-applications-container-active {
    color: #fff;
}

.user-applications-list {
    display: grid;
    list-style: none;
    padding: 0;
}

.application {
    padding: 1%;
    font-size: 1.3rem;
    width: 100%;
    margin-top: 1%;
    border-color: #fff;
    border-style: solid;
    white-space: normal;
    word-wrap: break-word;
}

.application button {}

.application-container {
    border-style: solid;
    border-width: 5px;
    border: #fff;
    width: 100%;
    padding: 24px 0 24px 0;
}

.application-clicked {
    background: #2d3436;
    border: none;
    color: #fff;
    font-size: 1.6rem;
}

/* Colors */

.approved {
    background: #00b894;
}

.pending {
    background: #fdcb6e;
    color: #2d3436;
}

.denied {
    background: #d63031;
}


/* Application Info Handling */

.application-info-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 24px 0 24px 0;
    padding: 0;
    width: 100%;
}

/* Newsletter Prompt */

.newsletter-prompt-container {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 24px;
    border-style: none none solid none;
    color: #fff;
}

.newsletter-prompt-container h1 {
    font-size: 3.6rem;
}

.newsletter-prompt-container .newsletter-signup {
    margin: 24px;
}


/* User Events */

.user-dashboard-events {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 24px;
    border-style: none none solid none;
    color: #fff;
}

/* Full Application View */

.user-review-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    border-style: none none solid none;
}

.user-review-container .review-items {
    grid-template-columns: repeat(3, 1fr);
}

.user-review-container .review-section {
    padding: 0;
    margin: 0;
    border-style: none none solid none;
}


/* Mobile Handling */

@media screen and (max-width: 1200px) {
    .dashboard-row-container {
        display: flex;
        flex-direction: column-reverse;
        padding: 1% 0 1% 0;
        list-style: none;
        justify-content: space-between;
        border-style: none none solid none;
        align-items: stretch;
        margin: 0;
    }

    .dashboard h1 {
        font-size: 2.6rem;
    }

    /* flexbox resizing */

    .dashboard-item-infobox {
        flex-basis: 25%;
        padding: 3%;
    }

    .dashboard-item-applications-container {
        flex-basis: 65%;
        padding: 3%;
        border-style: none none solid none;
    }

    .no-applications-message {
        margin: 0;
    }

    /* UserInfoBox */

    .user-info-box {}

    .user-info-box h3 {
        margin: 0;
    }

    .user-info-list {
        list-style: none;
        justify-content: space-evenly;
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 24px;
        padding: 1%;
        border-style: solid;
        margin-top: 24px;
    }

    .user-info-list h3 {
        font-size: 2rem;
    }

    .user-info-list p {
        font-size: 1.2rem;
    }

    /* Newsletter */

    .newsletter-prompt-container {
        padding: 3%;
    }

    /* Events */

    .user-dashboard-events {
        padding: 3%;
    }

    .application {
        width: 95%;
    }

    .newsletter-prompt-container button {
        width: 80%;
    }
}

@media screen and (max-width: 1000px) {
    .user-info-list {
        border-style: none;
        justify-content: space-between;
    }

    .user-info-list h3 {
        font-size: 1.8rem;
    }

    .user-info-list p {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 780px) {
    .application {
        font-size: 1.3rem;
    }

    .user-info-list {
        grid-template-columns: repeat(1, auto);
    }

    .user-info-list h3 {
        font-size: 1.8rem;
    }

    .user-info-list p {
        font-size: 1.1rem;
    }

    .dashboard h1 {
        font-size: 2.4rem;
    }

    .application-info-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 680px) {
    .application {
        font-size: 1.2rem;
    }

    .user-review-container .review-items {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 480px) {
    .application {
        font-size: 1.1rem;
    }

    .user-info-list h3 {
        font-size: 1.6rem;
    }

    .user-info-list p {
        font-size: 1rem;
    }

    .dashboard h1 {
        font-size: 2.2rem;
    }

    .newsletter-prompt-container button {
        width: 80%;
    }

}
.dashboard {
    background: #1e272e; 
    overflow: auto;
    border: #fff;
    color: #fff;
}
.testimonial {
}

.testimonial h2 {
    font-family: 'Poppins', sans-serif;
}

.testimonial div {
    flex-basis: 40%;
}

.testimonial img {
    max-height: 500px;
}

@media screen and (max-width: 1200px) {
    .sisterhood-testimonial h1 {
        font-size: 2.6rem;
        padding: 1%;
    }

    .testimonial h2 {
        font-size: 1.4rem;
        padding: 1%;
    }

    .testimonial img {
        max-height: 400px;
    }
}

@media screen and (max-width: 680px) {
    .testimonial img {
        max-height: 350px;
    }

    .testimonial h2 {
        font-size: 1.2rem;
        padding: 1%;
    }
}

@media screen and (max-width: 580px) {
    .sisterhood-testimonial h1 {
        font-size: 2rem;
        padding: 1%;
    }


    .testimonial img {
        max-height: 300px;
    }
}


@media screen and (max-width: 480px) {
    .testimonial h2 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 380px) {
    .testimonial img {
        max-height: 250px;
    }

    .testimonial h2 {
        font-size: .9rem;
    }
}
.sisterhood {
    background: #1e272e;
    overflow: auto;
    border: #fff;
    color: #fff;
}

/* Header */

.sisterhood-header-container {
    margin-top: 80px;
    border-style: none none solid none;
}

.sisterhood-header-intro-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.sisterhood-header {
    display: flex;
    justify-content: space-evenly;
    padding: 1%;
}

.sisterhood-header h1 { 
    color: #fff;
    font-size: 12rem;
    margin: 0;
    font-family: "Dancing Script", serif;
    font-weight: 600;
    align-self: center;
    text-align: center;
}

.sisterhood-header h2 {
    color: #f485ac;
    font-size: 6rem;
    margin: 0;
    font-family: "Dancing Script", serif;
    font-weight: 600;
    align-self: center;
    text-align: center;
}

.sisterhood-header-text-section {
    flex-basis: 80%;
}

.sisterhood-logo-section {
    flex-basis: 19%;
    display: flex;
}

.sisterhood-logo {
    max-width: 150px;
    align-self: center;
    text-align: center;
}

/* Body */

.sisterhood-section {
    padding: 0 1% 0 1%;
    border-style: none none solid none;
}
/* Descriptions */

.sisterhood-descriptions {
    display: flex;
}

.left-description {
    text-align: left;
    padding: 0 1% 0 1%;
}

.description-middle {
    border-style: none solid none solid;
    padding: 1%;
}

.right-description {
    text-align: right;
    padding: 0 1% 0 1%;
}

.founders-image {
    border-radius: 15px;
    max-width: 300px;
}

/* Info Container */

.pink-text {
    color: #fd79a8;
}

/* Info Container */

.info-container {
    padding: 1%;
    margin: 1%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    border-style: solid;
    color: #fff;
}

.mobile-info-container {
    padding: 0 1% 0 1%;
}

.mobile-info-container p {
    font-size: 1rem;
}

.mobile-info-header {
    font-size: 2.6rem;
    margin: 20px 0 20px 0;
}

/* Dotbox containers */

.dotbox-section {
}

.dotbox-container h1 {
    font-size: 3.6rem;
}

.left-aligned {
    text-align: left;
}

.right-aligned {
    text-align: right;
}


.sisterhood-item {
    margin-top: 24px;
    display: flex;
    justify-content: space-evenly;
}

.sisterhood-image {
    max-width: 100%;
    border-radius: 15px;
}

.learn-more {
    padding: 1%;
}

@media screen and (max-width: 1200px) {
    .sisterhood-header h1 {
        font-size: 10rem;
    }

    .sisterhood-header h2 {
        font-size: 5rem;
    }

    .info-container {
        width: 90%;
        border-style: none;
        padding: 1% 5% 1% 5%;
    }

    .info-container p {
        font-size: 1.1rem;
    }

    .sisterhood-section {
        padding: 0;
    }
    .sisterhood-descriptions {
        flex-direction: column;
    }

    .sisterhood-descriptions h1 {
        font-size: 2.6rem;
        margin: 20px 0 20px 0;
    }

    .sisterhood-descriptions p {
        font-size: 1rem;
    }

    .description-middle {
        border-style: none none solid none;
    }

    .left-description {
        border-style: none none solid none;
    }


}

@media screen and (max-width: 860px) {
    .sisterhood-header h2 {
        font-size: 4rem;
    }
}

@media screen and (max-width: 680px) {
    .sisterhood-header h1 {
        font-size: 8rem;
    }

    .sisterhood-header h2 {
        font-size: 3rem;
    }

    .info-container p {
        font-size: 1rem;
    }

    .sisterhood-descriptions h1 {
        font-size: 2.6rem;
    }

    .sisterhood-header-text-section {
        margin: 0 0 24px 0;
    }
}

@media screen and (max-width: 580px) {
    .sisterhood-header h1 {
        font-size: 7rem;
    }

    .sisterhood-header h2 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 580px) {
    .sisterhood-header h1 {
        font-size: 7rem;
    }

    .sisterhood-header h2 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 480px) {
    .sisterhood-header h1 {
        font-size: 5rem;
    }

    .left-description {
        text-align: center;
    }

    .right-description {
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .info-container p {
        font-size: .9rem;
    }

    .mobile-info-header {
        font-size: 2rem;
    }

    .mobile-info-container p {
        font-size: .9rem;
    }

    .sisterhood-descriptions h1 {
        font-size: 2rem;
    }

    .sisterhood-descriptions p {
        font-size: .9rem;
    }

}
.recover {
    background: #1e272e; 
    overflow: auto;
}

.recovery-form {
    height: 25%;
    margin: 120px 25% 48px 25%;
    border-style: solid;
    color: #fff;
}

.recovery-form h1 {
    font-size: 3.6rem;
}

.recovery-form form {
    margin: 0 25% 0 25%;
}

.recovery-buttons {
    display: flex;
    flex-direction: column;
    margin: 24px 0 24px 0;
}

.recovery-buttons form {
    width: 100%;
    margin: 0;
}

.recovery-buttons button {
    width: 100%;
    margin: 12px 0 12px 0;
}

.recovery-success-button form {
    width: 100%;
    margin: 24px 0 24px 0;
}

.denied-button:hover {
    background: rgb(238, 80, 80);
    transition: all 300ms ease-in-out;
}

@media screen and (max-width: 1200px) {
    .recovery-form {
        margin: 0;
        border-style: none none solid none;
    }

    .recovery-form .main-form {
        margin: 0 5% 0 5%;
    }
}
